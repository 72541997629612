/*************************************************************/
/*  COPYRIGHT ©2014 BLOOMNATION, INC. | ALL RIGHTS RESERVED  */
/*************************************************************/
/*** BLOOM THEME ****/
/********************/

:root {
    --bn--focus-color: #66afe9;
    --bn--light-gray: #EFEFEF;
    --bn--focus-outline: 5px auto var(--bn--focus-color);
    --bn--focus-outline-offset: 2px;
    --bn--primary-font-color: #09011CDE;
    --bn--secondary-font-color: #696475;
    --bn--transition-duration: 150ms;
    --bn--transition-easing: ease-in-out;
}

/********************************/
/* |   BOOTSTRAP OVERWRITES   | */
/********************************/
* {
    outline: 0;
}

.row {
    max-width: 1130px;
    margin: 0 auto;
    display: block;
}

section {
    clear: both;
}

.btn,
.dropdown-menu {
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -o-border-radius: 0px !important;
}

.center {
    text-align: center !important;
}

.dropdown-menu {
    box-shadow: 0 0 0;
    margin: 0;
    padding: 0;
    border: 0;
}

.dropdown-content--arrownav-desktop {
    overflow-y: auto;
}

.breadcrumb {
    background: none !important;
}

.breadcrumb > li + li:before {
    display: none;
}

.breadcrumb > li:after {
    content: ">";
    padding: 0 5px;
    color: #ccc;
}

.breadcrumb > li:last-child:after {
    display: none;
}

.breadcrumb > li.crumb-filter:after {
    content: ":";
}

body .btn:active, .btn.active {
    -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.125);
    -moz-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.125);
    -o-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.125);
}

.form-horizontal .control-label {
    font-weight: 300;
}

.pagination {
    clear: both;
}

i.input-group-addon {
    font-style: normal !important;
    float: left;
    height: 34px;
    width: 40px;
    line-height: 20px;
}

.hide {
    display: none;
}

*:active, *:focus {
    outline: 0;
}

*:focus-visible {
    outline: var(--bn--focus-outline);
    outline-offset: var(--bn--focus-outline-offset);
}

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.u-responsiveImg {
    height: auto;
    max-width: 100%;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/******************************/
/* |   MAGENTO OVERWRITES   | */
/******************************/
#orderInfo {
    width: auto !important;
    max-width: 700px !important;
    float: none !important;
    margin: 0 auto !important;
    clear: both;
    border-bottom: 1px #CCC solid;
    padding-bottom: 15px;
}

#orderProductGallery {
    display: none;
}

body.blog-post-view div.main, body.blog-post-view div.container div.col-xs-12.col-sm-12, body.blog-index-list div.main, body.blog-index-list div.container div.col-xs-12.col-sm-12 {
    box-shadow: 0 0 0 !important;
    -wekbit-box-shadow: 0 0 0 !important;
    -moz-box-shadow: 0 0 0 !important;
    -o-box-shadow: 0 0 0 !important;
    margin-top: 0 !important;
}

.centerRow {
    float: left;
    margin: 0 auto;
}

article {
    padding-bottom: 20px;
    margin-bottom: 40px;
    clear: both;
    border-bottom: 1px #CCC solid;
}

body.blog-post-view .entry-header img.thumbnail, body.blog-index-list .entry-header img.thumbnail {
    border: 0 !important;
    box-shadow: 0 0 0 !important;
    border: 0 !important;
}

body.blog-post-view .entry-header .entry-title, body.blog-index-list .entry-header .entry-title {
    font-size: 28px !important;
    font-weight: 100 !important;
}

.widget_recent_entries ul {
    list-style: none;
    padding: 0;
    padding-left: 10px;
}

.customArrangement .slider-selection {
    display: none;
}

input, textarea, select {
    font-weight: 200 !important;
}

/***********************************/
/* |   HEADING BANNER ELEMENTS   | */
/***********************************/
#heading_banner {
    background: #F1F2F2;
    height: 46px;
    width: 100%;
    line-height: 44px;
    font-size: 20px;
    font-weight: 400;
    color: #444;
    top: 0;
    left: 0;
    text-align: center;
    position: relative;
    z-index: 9999;
}

/***************************/
/* |   HEADER ELEMENTS   | */
/***************************/
#header {
    width: 100%;
    min-height: 200px;
    padding: 50px 0 0;
    position: relative;
    z-index: 1000;
}

.accessibility-widget {
    z-index: 2147483647;
    position: absolute;
    top: 0;
}

.accessibility-widget-overlay {
    z-index: 2147483646;
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
}

.accessibility-widget #header-accessibilityPopup {
    /* top must be exact negative of height */
    top: -205px;
    transition: top 300ms;
    display: none;
}

@media (prefers-reduced-motion) {
    .accessibility-widget #header-accessibilityPopup {
        transition: top 0ms;
    }
}

.header-accessibilityInnerContainer {
    position: relative;
}

#header-accessibilityPopupBtn {
    position: relative;
    padding: 0;
    z-index: 2147483647;
    border: none;
    background: transparent;
    width: 42px;
    height: 42px;
}

#header-accessibilityPopupBtn svg {
    filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, .12)) drop-shadow(0px 1px 10px rgba(0, 4, 4, .10));
    -webkit-filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, .12)) drop-shadow(0px 1px 10px rgba(0, 4, 4, .10));
}

.ada-icon-tab {
    display: none;
}

@media screen and (min-width: 768px) {
    .ada-icon-dot {
        display: none;
    }

    .ada-icon-tab {
        display: block;
    }
}

#header-accessibilityPopupBtn:focus-visible {
    border: 1px solid blue;
}

#header-accessibilityPopup {
    border-radius: 0 0 4px 4px;
    position: absolute;
    width: 210px;
    background: #fff;
    left: 21px;
    z-index: 2147483646;
    padding: 24px;
    height: 205px;
    box-shadow: 0 4px 4px rgb(0, 0, 0, .1), 0 1px 10px rgb(0, 0, 0, .12);
}

#header-accessibilityPopup p {
    font-size: 14px;
    color: rgba(9, 1, 28, 0.87);
}

#header-accessibilityPopup a {
    color: #0279FF;
}

.header-innerContainer {
    min-height: 100px;
}

.header-nav {
    background: var(--bn--light-gray);
    width: 100%;
    min-height: 50px;
}

.navigation {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    position: relative;
    z-index: 999;
}

.nav-itemWrapper {
    display: inline-block;
    font-weight: 200;
    position: relative;
    font-size: 16px;
}

.nav-item {
    position: relative;
    z-index: 999;
    display: block;
    padding: 0.89em 0.986em;
    text-transform: uppercase;
}

.nav-item:visited,
.nav-item:hover,
.nav-item:focus {
    text-decoration: none;
}

.nav-itemWrapper--dropdown:hover .nav-item,
.nav-itemWrapper.open .nav-item,
.nav-itemWrapper .nav-item:hover {
    background: rgba(255, 255, 255, 0.3);
    color: #777;
}

.nav-item--dropdown {
    cursor: default;
}

.header-subnav {
    z-index: 9999;
    position: absolute;
    margin-left: -1px;
    border: 1px #CCC solid;
    border-top: 0;
    text-align: left;
}

.header-subnav .nav-item--subnav {
    padding: 15px 25px;
    color: #777;
    font-weight: 200;
    position: relative;
    z-index: 99999;
    border-top: 1px solid hsla(0, 0%, 100%, .12);
}

.header-subnav .nav-item--subnav:hover {
    background: var(--bn--light-gray);
}

a.nav-item--subnav:focus,
a.nav-item--subnav:active,
a.nav-item--subnav:visited {
    outline: revert;
}

.nav-itemWrapper--navToggle {
    display: none;
    padding-top: 4px;
}

/* Header Elements */
.infoHeading {
    margin-bottom: 25px;
    font-size: 15px;
    font-weight: 200;
}

.infoHeading-item {
    display: block;
}

.header-logo {
    margin-top: -25px;
}

.companyLogo-img {
    display: block;
    margin: 0 auto;
    max-width: 347px;
    max-height: auto;
    height: auto;
}

.header-cartBtn {
    background: var(--bn--light-gray);
    width: 125px;
    height: 50px;
    line-height: 38px;
    border: 0;
    float: right;
    margin-bottom: 25px;
}

.header-cartText {
    position: absolute;
    margin-left: 8px;
    margin-top: -2px;
    color: #555;
}

.header-cartIcon {
    font-size: 18px;
    margin-left: -60px;
    color: #888;
}

.section .header-cartBtn-wrapper .my-account-mob .header-cartIcon {
    margin-top: -1px !important;
}

.checkout-cart-container .alert {
    border-radius: 3px;
    background-color: #fffbe8;
    border: solid 1px #ddc337;
    margin-left: 15px;
    padding: 15px 0 15px 20px;
    color: #555;
    font-size: 13px;
    text-align: left;
}

.checkout-cart-container .alert .pickup-only-warning {
    text-align: center;
    border-radius: 3px;
    margin-left: 15px;
    background-color: #fffbe8 !important;
    border: solid 1px #ddc337 !important;
    padding: 10px 0 10px 0px !important;
    color: #555;
    font-size: 13px;
    min-height: 36px;
}

.checkout-cart-container .alert .pickup-only-warning-alert {
    margin-left: 10px;
}

.product_details-innerContainer .alert img.pickup-only-warning-alert {
    height: 26px;
    font-weight: 200 !important;
    margin-left: 14px;
}

.checkout-cart-container .alert .close {
    margin-top: -3px;
    position: static;
    margin-right: 15px;
    opacity: 1;
    cursor: pointer;
}

.checkout-cart-container .alert .close-icon {
    color: #777;
}

.warning-triangle {
    float: left;
}

.warning-triangle-image {
    width: 24px;
    margin-right: 10px;
}

.header-cart .my-account-button-wrapper {
    float: left;
    margin-top: 11px;
}

/****************************/
/* |   CONTENT ELEMENTS   | */
/****************************/
#content {
    position: relative;
    z-index: 999;
}

.catalog-product-view #content {
    position: inherit;
}

.banner-img {
    background: var(--bn--light-gray);
    width: 100%;
    height: auto;
    border: 0px;
}

/* Catalog Elements */
@media screen and (max-width: 768px) {
    .catalog-header {
        font-weight: var(--bn--font-normal);
        font-size: 12px;
        line-height: 18px;
        margin-top: 1em;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.itemListings-header {
    font-size: 12px;
    line-height: 18px;
}

@media screen and (min-width: 768px) {
    .itemListings-header {
        font-size: 14px;
        line-height: normal;
    }
}

.itemListings-header--featured,
.itemListings-subheader--browseDesigns {
    color: #4B4B4B;
    font-size: 20px;
    font-weight: var(--bn--font-normal);
    line-height: 28px;
}

@media (min-width: 768px) {
    .itemListings-header--featured,
    .itemListings-subheader--browseDesigns {
        font-size: 32px;
        font-weight: var(--bn--font-light) !important;
        text-transform: capitalize;
    }
}

.itemListings-header__send-title {
    font-weight: var(--bn--font-normal);
    text-align: left;
    margin-top: 0;
    padding-top: 0;
}

@media screen and (min-width: 768px) {
    .itemListings-header__send-title {
        margin-top: 20px;
        text-align: right;
    }

    .mobile-divider {
        display: none;
    }
}

.itemListings-subheaderLink {
    font-size: 14px;
    text-transform: uppercase;
    text-indent: 2px;
    font-weight: 200;
}

.item {
    margin: 10px 0;
    position: relative;
}

.item:hover {
    text-decoration: none;
}

.item:hover .viewDetails-btn {
    opacity: 1;
}

.category-products .item {
    padding-right: 0;
}

.category-products h3.divider {
    clear: both;
}

.featured_listings_block .item:first-child {
    padding-left: auto !important;
}

.item-img {
    background: var(--bn--light-gray);
    border: 0px;
    width: 100%;
    height: auto;
}

.itemInfo-deliveryText {
    display: block;
    width: 100%;
    text-transform: uppercase;
    padding: 5px 0;
}

.item-wrapper .itemInfo-wrapper .itemInfo-deliveryText span,
.item-wrapper .itemInfo-wrapper .pickup-only span {
    font-size: 10px;
    margin-top: -4px;
    margin-left: 0px;
    font-weight: 100;
}

.itemInfo-deliveryText .fa-stack {
    font-size: 13px;
}

.itemInfo-deliveryText .fa-truck {
    text-indent: -0.1em;
}

.item_listing-information .add_notice {
    display: block;
    height: 44px;
}

.itemInfo-deliveryText .icon-circle-add {
    background: url(../images/icon-circle-add.svg) 0 0 no-repeat;
    display: inline-block;
    background-size: 100%;
    width: 15px;
    height: 15px;
    position: absolute;
    margin-top: 4px;
}

.item-wrapper .itemInfo-wrapper .itemInfo-deliveryText span.add-to-cart-msg {
    height: 16px;
    font-size: 12px;
    font-weight: 200 !important;
    text-align: left;
    color: #777777;
    margin-left: 24px;
    margin-top: 4px;
    float: left;
}

.item-wrapper .itemInfo-wrapper .itemInfo-deliveryText span.add-to-cart-msg:hover {
    color: #555;
}

i.pickup-only-icon {
    background: url(../images/icon-pickup.svg) 0 0 no-repeat;
    display: inline-block;
    background-size: 100%;
    width: 18px;
    height: 24px;
    position: relative;
    top: 5px;
}

.icon-compare-like-outline {
    background: url(../images/icon-compare-like-outline.svg) 0 0 no-repeat;
    display: inline-block;
    background-size: 100%;
    width: 12px;
    height: 14px;
    font-size: 14px;
    color: #777;
    float: right;
    line-height: 21px;
    position: absolute;
    right: 5px;
    top: 7px;
}

.icon-compare-like-fill {
    background: url(../images/icon-compare-liked.svg) 0 0 no-repeat;
    display: inline-block;
    background-size: 100%;
    width: 12px;
    height: 14px;
    font-size: 14px;
    color: #777;
    float: right;
    line-height: 21px;
    position: absolute;
    right: 6px;
    top: 8px;
}

/* add thin close icon */
.fa-times-thin:before {
    content: '\00d7';
}

.itemListings-container--home .heart-gif, .catalog-category-view .heart-gif {
    position: fixed;
    width: 56px;
    left: -120px;
    z-index: 9999;
}

.deliveryText-background {
    background-color: #fff;
    opacity: .1;
}

.floating-like-button {
    cursor: pointer;
    z-index: 9999;
    width: 140px;
    height: 82px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: -1px 2px 4px 0 rgba(0, 0, 0, 0.27), 1px 2px 4px 0 rgba(0, 0, 0, 0.27);
}

.floating-like-button .title {
    display: inline-block;
    margin: 5px 0 5px 0;
    padding: 0px 12px;
    height: 19px;
    font-family: var(--bn--primary-font);
    font-size: 14px;
    font-weight: 900;
    text-align: left;
    color: #777777;
}

.floating-like-button .divider {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.floating-like-button .content {
    padding-left: 50px;
}

.floating-like-button .bn-heart-wrapper,
.floating-like-button .liked-items-number,
.floating-like-button .view-all-button {
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
}

.floating-like-button .bn-heart-wrapper {
    float: left;
    border-radius: 50px;
    padding: 3px 6px;
    position: absolute;
    width: 30px;
    height: 30px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.24);
}

.floating-like-button .liked-items-number {
    left: 86px;
    top: 45px;
    position: absolute;
    width: 10px;
    height: 23px;
    font-family: var(--bn--primary-font);
    font-size: 17px;
    color: #555555;
}

.floating-like-button .bn-heart-clicked {
    background: url(../images/icon-compare-liked.svg) 0 0 no-repeat;
    display: inline-block;
    background-size: 100%;
    width: 15px;
    height: 15px;
    font-size: 14px;
    color: #777;
    float: right;
    line-height: 21px;
    position: absolute;
    right: 7px;
    top: 10px;
}

.floating-like-button .view-all-button {
    opacity: 0;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    color: #777777;
    font-size: 12px;
    background: #ffffff;
    padding: 5px 8px 5px 8px;
    border: 1px solid #777;
    text-decoration: none;
    position: absolute;
    right: -75px;
}

.floating-like-button:hover .content > .bn-heart-wrapper,
.floating-like-button:hover .content > .liked-items-number {
    transform: translate(-35px, 0);
    -webkit-transform: translate(-35px, 0);
    -moz-transform: translate(-35px, 0);
    -o-transform: translate(-35px, 0);
    -ms-transform: translate(-35px, 0);
}

.floating-like-button:hover .content > .view-all-button {
    right: 0px;
    opacity: 1;
    transform: translate(-0.5em, 0);
    -webkit-transform: translate(-0.5em, 0);
    -moz-transform: translate(-0.5em, 0);
    -o-transform: translate(-0.5em, 0);
    -ms-transform: translate(-0.5em, 0);
}

.no-blooms-wrapper {
    text-align: center;
    font-family: var(--bn--primary-font);
}

.no-blooms-wrapper .no-blooms-title {
    height: 27px;
    font-size: 20px;
    font-weight: 200;
    color: #555555;
    margin-top: 25px;
    font-family: var(--bn--primary-font);
}

.no-blooms-wrapper .bloonsbap-bouquet-border {
    border: 1px solid #777;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    margin: 15px auto 0;
    background: #f5f5f5;
    overflow: hidden;
}

.no-blooms-wrapper .bloonsbap-bouquet-border .fa-heart-o {
    font-size: 20px;
    border-radius: 50%;
    border: 1px solid #777;
    padding: 8px 8px 8px 8px;
    position: absolute;
    margin: 0px 0px 0px -19px;
    background: #fff;
}

.no-blooms-wrapper .illustration-bloomsnap-bouquet {
    background: url(../images/illustration-bloomsnap-bouquet.svg) 0 0 no-repeat;
    display: inline-block;
    background-size: 100%;
    width: 120px;
    height: 120px;
    margin-top: 8px;
    margin-left: -7px;
}

.no-blooms-wrapper .no-blooms-text {
    font-size: 14px;
    color: #555555;
    margin-top: 20px;
    margin-bottom: 25px;
    font-weight: 200;
}

.no-blooms-wrapper .no-blooms-text div {
    margin: 6px 0px;
}

.no-blooms-wrapper .shop-now-btn {
    position: relative;
    width: 174px;
    height: 48px;
    background-color: #808080;
    border: none;
    color: #ffffff;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 200;
    line-height: 1.2;
    text-align: center;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.no-blooms-wrapper .shop-now-btn:hover {
    cursor: pointer;
}

.no-blooms-wrapper .shop-now-btn a {
    text-decoration: none;
    color: #ffffff;
}

.item-wrapper-overlay {
    transition: .5s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    width: 90%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    color: #777777;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.24);
    opacity: 1.8;
    margin-top: -1px;
}

.item-wrapper-overlay .overlay-text {
    margin-top: 45%;
    font-size: 17px;
    font-weight: 200;
}

.item-wrapper-overlay .undo-btn {
    border: 1px solid #bbb;
    color: #777777;
    padding: 5px 15px;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    line-height: 1.2;
    text-align: center;
    margin-top: 25px;
    font-weight: 200;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
}

.item-wrapper-overlay .undo-btn:hover {
    border: 1px solid #000;
}

.item-wrapper-overlay .undo-btn a {
    text-decoration: none;
    color: #777777;
}

.itemInfo {
    display: block;
    padding: 0 3px;
    font-weight: 200;
    font-size: 13px;
    margin-top: -5px;
}

.itemInfo:hover {
    text-decoration: underline;
}

.item_listing-information .itemInfo {
    margin-top: 0px;
}

.itemInfo-price,
.itemInfo-priceContainer .price {
    font-weight: 400;
    font-size: 12px;
    float: right;
    padding-left: 10px;
}

.item-viewDetails {
    padding: 5px;
    clear: both;
    display: block;
    margin-top: -44px;
}

.item--extra.active .viewDetails-btn {
    opacity: 1;
}

.additional_gift-image .item-viewDetails {
    padding: 15px 5px;
    clear: both;
    display: block;
    margin-top: -62px;
}

.viewDetails-btn {
    display: block;
    opacity: 0;
    width: 100%;
    float: none;
    font-weight: 200;
}

.orderForm-additional_gifts .item {
    width: 22%;
    display: inline-block;
    float: none;
    margin-right: 10px;
    cursor: pointer;
    vertical-align: top;
}

.orderForm-additional_gifts .item:first-child {
    margin-left: 0;
}

.orderForm-additional_gifts .itemInfo {
    line-height: 18px;
}

.additional_gift-image {
    margin-bottom: 10px;
}

.orderForm-additional_gifts .item img {
    width: 100%;
}

.orderForm-additional_gifts .item .item_name {
    line-height: 18px;
}

.item-wrapper {
    font-family: var(--bn--primary-font);
}

.item-wrapper .item_name {
    height: 46px;
    padding-top: 12px !important;
    display: flex;
    align-items: center;
}

.item-wrapper .item_listing-information .pickup-only {
    padding: 10px 10px 11px;
}

.item-wrapper .item_listing-information .pickup-only-label {
    font-size: 12px;
    text-transform: uppercase;
    margin: 0px 0px 0px 7px;
}

.item-wrapper .item_listing-information .divider {
    margin-bottom: 0px;
}

.itemInfo-price--extra,
.itemInfo-price--extra .price {
    float: none;
    padding: 0;
}

.itemInfo-price--extra .price {
    padding-top: 5px;
}

.breadcrumb {
    font-weight: 100;
    font-style: italic;
    font-size: .9em;
    padding-top: 20px;
    margin-bottom: 10px;
}

.social-box {
    padding: 10px 20px 10px 0;
}

.social-box .social {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.social-box .social li {
    display: inline-block;
    margin-left: 15px;
    font-size: 24px;
    color: rgba(0, 0, 0, .3);
}

.social-box li:first-child {
    margin-left: 0;
}

.social-box .fa {
    color: rgba(0, 0, 0, 0.4);
}

.social-box .fa:hover {
    transition: color .3s ease;
}

.bn_florist_link {
    color: inherit;
}

.bn_florist_link:hover {
    color: inherit;
    cursor: auto;
    text-decoration: none;
}

.custom-quick_sort-top {
    display: none;
}

.bar-quick_select,
.search_bar {
    background: var(--bn--light-gray);
    max-height: 46px;
    min-height: 46px;
}

.bar-quick_sort .search_bar {
    min-height: 46px;
    padding: 0;
}

.bar-quick_sort .search_bar > div {
    background: #CCC;
    height: 100%;
    padding: 9px 10px;
}

.bar-quick_sort .search_bar i {
    display: block;
    float: left;
    padding-top: 6px;
    margin-right: -20px;
    position: relative;
    padding-left: 7px;
    z-index: 999;
}

.bar-quick_sort .search_bar input {
    display: block;
    font-weight: 200;
    padding: 2px 5px 2px 27px;
    height: 28px;
    border: 1px var(--bn--light-gray) solid;
    position: relative;
    z-index: 99;
}

.bar-quick_sort .search_bar button {
    text-align: center;
    padding: 4px 0;
    border: 0;
}

.bar-flower_select {
    display: block;
    list-style: none;
    margin-bottom: 0px;
    padding-left: 0;
    max-height: inherit;
    overflow: hidden;
    height: 45px;
}

.bar-flower_select > li {
    display: inline-block;
}

.bar-helper_text,
.bar-flower_select > li > a {
    display: block;
    padding: 13px 20px;
    font-weight: 200;
    font-size: 1em;
}

.bar-helper_text {
    color: #888;
    text-transform: uppercase;
    font-style: italic;
    font-size: .8em;
    line-height: 1.8em;
    float: left;
    padding-left: 0;
}

.listings .note-msg {
    margin: 80px 20px 40px;
    font-size: 22px;
    font-weight: 200;
}

.browse-more-options-container button {
    font-size: 14px;
    color: #4B4B4B;
    border: 1px solid #4B4B4B;
    font-weight: 200;
    margin-left: 20px;
    padding: 10px;
    background-color: transparent;
}

.divider-no-products {
    padding-bottom: 30px;
    margin-left: 20px;
}

.mobile-divider {
    margin-top: 20px;
    margin-left: 20px;
}

.you-may-also-like-header {
    margin-left: 20px;
    margin-top: 20px;
    font-size: 22px;
    font-weight: 200;
    color: #4B4B4B;
}

.sidebar-sort {
    margin: 20px 0;
    padding-left: 0;
}

.listings .sidebar-sort > div {
    height: 100%;
    padding: 10px 20px;
}

.listings .sidebar-sort > div.sidebar {
    background: var(--bn--light-gray);
}

.listings .sidebar-sort h6 {
    color: #888;
}

.item_listings .page-title {
    display: none;
    visibility: hidden;
}

.sorting-clear_all {
    float: right;
    color: #333;
    font-weight: 200;
    padding-top: 5px;
}

.sorting-option-title {
    display: block;
    font-size: 15px;
    font-weight: 300 !important;
    padding-bottom: 5px;
    margin-top: 20px;
}

.sorting-option-title:first-child {
    margin-top: 0;
}

.sorting-option-title a {
    display: block;
    float: right;
    color: #333 !important;
    font-size: 15px;
    line-height: 25px;
}

.sorting-options {
    list-style: none;
    padding: 0 5px 0;
}

.sorting-options li {
    clear: both;
}

.sorting-options li label {
    font-weight: 200;
    font-size: 15px;
    display: block;
    cursor: pointer;
}

.sorting-options .fa {
    width: 25px;
    font-size: 19px;
    display: block;
    float: left;
    padding-right: 10px;
    margin-top: 1px;
}

.sorting-options li input {
    display: none;
}

.sidebar-title {
    background: var(--bn--light-gray);
    padding: 10px 15px;
    font-size: 18px;
    font-weight: 200;
}

.sidebar-subscriptions {
    background: var(--bn--light-gray);
    padding: 8px;
}

.sidebar-subscriptions-title {
    font-size: 18px;
    margin-top: 0;
}

.sidebar-subscriptions-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.sidebar-subscriptions-product-container {
    display: flex;
    gap: 8px;
}

@media (max-width: 900px) {
    .sidebar-subscriptions-product-container {
        flex-direction: column;
    }
}

.sidebar-subscriptions-description-container {
    max-height: 80px;
}

.sidebar-subscriptions-description-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.listings .sidebar-sort > div.sidebar-best_selling {
    padding: 0;
}

.sidebar-best_selling .delivery_notice {
    display: none;
}

.delivery_notice .fa-stack-1x {
    left: -4px !important;
}

.sidebar-best_selling {
    background: #fff;
}

.sidebar-best_selling .item {
    margin-bottom: 10px;
    float: none !important;
    display: flex;
    clear: both;
}

.sidebar-best_selling img {
    max-width: 75px;
    height: 80px;
    margin-right: 10px;
}

.sidebar-best_selling span {
    float: none;
    display: inline;
    line-height: 20px;
    padding: 0;
    text-decoration: none;
    color: #000000;
}

.sidebar-best_selling .itemInfo-priceContainer {
    display: block;
    margin-top: 5px;
}

.sidebar-best_selling span.itemInfo-price {
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 16px;
}

.featured_listings_block {
    background: #fafafa;
    margin-top: 20px;
    margin-right: -10px;
    padding-bottom: 20px;
}

.product_details-image_gallery .active-image_gallery-image {
    background: var(--bn--light-gray);
    height: auto;
    width: 100%;
}

.product_details-image_gallery .image_gallery-images img {
    background: var(--bn--light-gray);
    width: 80px;
    margin: 10px;
    margin-right: 0;
}

.product_details-image_gallery .image_gallery-images img:first-of-type {
    margin-left: 0px;
}

.mediaGallery__container {
    width: 100%;
}

.mediaGallery__container--video {
    margin-top: 10px;
    width: 100%;
    position: relative;
}

.mediaGallery__container--video-landscape {
    padding-bottom: 56.25%;
}

.mediaGallery__container--video-portrait {
    padding-bottom: 177.77%;
}

.mediaGallery__container--video blockquote {
    border: none !important;
}

.mediaGallery__container--video > .video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.mediaGallery__item {
    margin-top: 3px;
    display: none;
    max-width: 20%;
}

.mediaGallery__item.active {
    display: inline-block;
}

.mediaGallery__img {
    height: 100%;
    width: 100%;
}

.mediaGallery__link:hover {
    text-decoration: none;
}

.product-main-image-wrapper {
    position: relative;
}

.product-main-image-wrapper .product-main-image-caption {
    position: absolute;
    bottom: 0;
    padding: 0 4px 4px;
    width: 100%;
    display: none;
}

.product-main-image-wrapper .product-main-image-caption.active {
    display: flex;
}

.product-main-image-wrapper .product-main-image-caption .image-caption {
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #3F3F3F;
    padding: 8px 32px;
}

.customArrangement .orderForm-delivery_date {
    max-width: 470px;
}

#orderForm .orderForm-delivery_zip input {
    width: 100%;
    padding: 13px;
    font-size: 23px;
    margin-top: 8px;
    font-weight: 300;
}

#orderForm .orderForm-delivery_date .btn {
    background: none !important;
    color: #333;
}

#orderForm .orderForm-delivery_date {
    padding: 0;
    margin: 0;
}

#orderForm .btn-order-date {
    padding: 6px 0;
}

#orderForm .btn-order-date:nth-of-type(2) {
    padding-left: 2px;
    padding-right: 2px;
}

#orderForm .btn-order-date:nth-of-type(3) {
    padding-left: 4px;
}

.orderForm-checkout {
    text-align: center;
}

#orderForm .orderForm-checkout > div:first-child {
    padding-left: 0;
}

#orderForm .orderForm-checkout .btn {
    width: 100%;
    height: 60px;
    line-height: 13px;
    border: 1px #888 solid;
}

#orderForm .orderForm-checkout .orderForm-order-now {
    font-size: 20px;
    font-weight: 300;
}

#orderForm .orderForm-checkout .btn i {
    display: block !important;
    font-weight: 100;
    font-style: italic;
    font-size: 12px;
}

#orderForm .orderForm-checkout .alert-unavailable-product {
    color: #b94a48;
}

.bloom-samedayWrapper {
    margin: 0 auto;
    padding-left: 0;
    text-align: center;
    display: inline-block;
    font-size: 15px;
    color: #616161;
}

.product_details-orderForm .bloom-sameday_info {
    float: left;
}

.product_details-orderForm .todayDelivery {
    margin-top: 3px;
}

#orderForm .price-additional-text {
    padding: 0;
    font-weight: 200 !important;
}

#onestepcheckout-form .recipient-phone-help-msg svg {
    width: 12px;
    height: 12px;
    margin-right: 5px;
}

#onestepcheckout-form .recipient-phone-help-msg svg path {
    fill: #777777;
}

#onestepcheckout-form ul.delivery_address_type {
    margin: 10px 0px 0px -40px;
    list-style-type: none;
}

#onestepcheckout-form ul.delivery_address_type li {
    width: 180px;
    float: left;
}

#onestepcheckout-form ul.delivery_address_type li img {
    display: inline;
    margin: 0 3px 0 3px;
    width: 20px;
}

#onestepcheckout-form ul.delivery_address_type li img.address-type-selected,
#onestepcheckout-form .shipping_information img.custom-radio-selected {
    display: none;
}

#onestepcheckout-form input[type="radio"] {
    display: none
}

#onestepcheckout-form #credit-card-payment-title {
    display: inline;
}

#onestepcheckout-form #p_method_house_account {
    margin: 0 5px;
}

#onestepcheckout-form input[type="radio"] + label.radio-label {
    color: #555555;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
    width: 177px;
    font-family: var(--bn--primary-font);
    font-size: 14px;
    margin: 0px 0px 10px 0px;
    font-weight: normal;
}

#onestepcheckout-form .delivery-divider {
    margin-top: 30px;
}

#onestepcheckout-form .address-type-divider {
    margin: 30px 12px 10px;
}

#onestepcheckout-form .address-divider {
    margin: 25px 0px 15px 0px;
}

#onestepcheckout-form input[type="radio"]:checked + label img.address-type-unselected {
    display: none;
}

#onestepcheckout-form input[type="radio"]:checked + label img.address-type-selected {
    display: inline;
}

#onestepcheckout-form input[type="radio"]:checked + label img.custom-radio-selected {
    display: block;
}

#onestepcheckout-form input[type="radio"]:checked + label img.custom-radio-unselected {
    display: none;
}


#onestepcheckout-form .checkout_fulfillment-methods input[type="radio"] + label.radio-label {
    border: none;
    width: initial;
    font-size: 14px;
    font-weight: var(--bn--font-demibold);
    position: relative;
    padding-left: 32px;
    margin: 0;
}

#onestepcheckout-form .checkout_fulfillment-methods input[type="radio"] + label.radio-label > span {
    font-weight: var(--bn--font-medium);
    font-size: 12px;
}

#onestepcheckout-form .checkout_fulfillment-methods input[type="radio"] + label > div {
    margin-top: -3px;
    position: absolute;
    left: 0;
}

.checkout_fulfillment-method-pickup {
    border-top: 1px solid var(--bn--light-gray);
    padding-top: 6px;
    margin-top: 6px;
}

#timed-delivery-range-table td, #timed-delivery-range-table th {
    border: solid #D6D6D6 1px;
    padding: 4px 10px;
    color: #7b7b7b;
    text-align: center;
}

#timed-delivery-range-table th {
    font-weight: bold;
}

td.timed-delivery-range {
    color: #45b1a3;
}

td.timed-delivery-range {
    cursor: hand;
}

td.unavailable-range {
    background-color: #F1F1F1;
    color: #BABABA;
}

#onestepcheckout-form td.timed-delivery-range input[type="radio"]:checked + label {
    color: #ffffff;
    background-color: #45b1a3;
    font-weight: bold !important;
    padding: 0 13px !important;
}

@media (max-width: 767px) {
    .breadcrumb {
        display: none;
    }
}

@media (max-width: 550px) {
    .overlayView {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        background: rgba(0, 0, 0, .6);
        opacity: 0;
        -webkit-transition: 100ms opacity ease;
        transition: 100ms opacity ease;
    }

    .overlayView.isActive {
        opacity: 1;
    }
}

.pagination-page_count {
    display: block;
    padding-top: 25px;
    font-size: 12px;
}

.toolbar-bottom .clearfix {
    display: none;
}

@media screen and (max-width: 768px) {
    .listings :not(.toolbar-bottom) > .pagination {
        display: none;
        visibility: hidden;
    }

    :not(.toolbar-bottom) .pagination-page_count {
        color: #666666;
        margin-top: -2.25em;
        padding-top: 0;
    }
}

.toolbar-bottom .pagination-page_count {
    padding-top: 55px;
}

.customArrangement input,
.customArrangement select {
    max-width: 280px;
}

.customArrangement textarea {
    max-width: 470px;
}

.customArrangement textarea {
    min-height: 100px;
    max-height: 400px;
}

.customArrangement select option:first-child {
    font-weight: 100;
    font-style: italic;
}

.customArrangement .slider {
    width: 100% !important;
    max-width: 470px;
    display: block;
    margin: 7px 0 0 16px;
}

.customArrangement .custom_order-size_controls .custom_amount-slider {
    max-width: 500px;
}

.customArrangement .custom_order-size_controls #formPrice .input-group-addon {
    width: 15%;
    max-width: 45px;
}

.customArrangement .custom_order-size_controls #formPrice input {
    width: 85%;
    max-width: 220px;
}

.customArrangement .custom_order-size_controls #formPrice input.error {
    border: 1px solid #f00;
}

.customArrangement .custom_order-size_controls #formPrice a {
    float: left;
    margin: 10px 0 0 10px;
}

.customArrangement .custom_order-size_controls #formPrice small.error {
    display: inline-block;
    color: red;
}

.customArrangement .orderForm-delivery_date .datepickr-wrapper small.error {
    display: inline-block;
    color: red;
}

.customArrangement .custom_order-size_controls .custom_amount-slider > span {
    text-align: center;
    padding: 0 !important;
}

.customArrangement .custom_order-size_controls .custom_amount-slider > span:first-of-type {
    text-align: left;
    margin-left: -20px;
    margin-right: 20px;
    padding-left: 20px !important;
}

.customArrangement .custom_order-size_controls .custom_amount-slider > span:last-of-type {
    text-align: right;
    margin-right: -10px;
    margin-left: 10px;
}

.customArrangement .custom_order-size_controls .custom_amount-slider > span > span {
    display: block;
    font-size: 15px;
}

.customArrangement .custom_order-size_controls .custom_amount-slider > div > span small {
    display: block;
    font-size: 12px;
}

.customArrangement .custom_order-size_controls .select-custom_price {
    margin-top: 10px;
    clear: both;
}

.customArrangement button {
    display: block;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: 200;
    margin: 25px 0;
}

#custom_arrangement_alert {
    border: 1px solid #DDC337;
    background-color: #FFFFDB;
    padding: 14px;
    border-radius: 3px;
}

#custom_arrangement_alert p {
    font-size: 11px;
    text-align: center;
}

.custom_arrangement_header {
    margin: 20px 0 40px;
}

.custom_arrangement_header h2 {
    font-size: 42px;
    margin-bottom: 2px;
}

.custom_arrangement_header small {
    text-transform: uppercase;
    font-size: 12px;
}

#orderForm.customArrangement .orderForm-delivery_date .btn-order-date-viewmore {
    margin: 0;
}

#orderForm.customArrangement .deliveryDate-btnWrapper {
    max-width: 265px;
}

/* Custom Arrangement Media Queries */
@media (max-width: 768px) {
    #orderForm.customArrangement {
        max-width: 500px;
        margin: 0 auto;
    }

    #orderForm.customArrangement .orderForm-delivery_date .btn-order-date-viewmore {
        max-width: none;
    }
}

/**********************************/
/* |   SLIDESHOW  | */
/**********************************/

#slides .slidesjs-navigation {
    margin-top: 5px;
}

.slidesjs-pagination {
    list-style: none;
    text-align: center;
    width: 80%;
    max-width: 440px;
    min-height: 15px;
    padding: 0;
    margin: 0 auto;
}

.slidesjs-pagination li {
    display: inline-block;
    margin: 0 1px 2px;
}

.slidesjs-pagination li a {
    display: block;
    cursor: pointer;
    text-indent: -9999px;
    width: 12px;
    height: 12px;
    margin: 0 3px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    /* Nav Dot Background Color */
    background-color: #b6b6b6;
    /* Nav Dot Ring Color */
    -webkit-box-shadow: inset 0 0 0 2px #b6b6b6;
    -moz-box-shadow: inset 0 0 0 2px #b6b6b6;
    box-shadow: inset 0 0 0 2px #b6b6b6;
}

.slidesjs-pagination li a.active,
.slidesjs-pagination li a:hover.active {
    /* Nav Dot Active Color */
    background-color: #9EC39A;
}

#slides a:link,
#slides a:visited {
    color: #333;
}

#slides a:hover,
#slides a:active {
    color: #9e2020;
}

/* Hide Text Buttons (Play, Next, Stop) */
.slidesjs-stop,
.slidesjs-stop:hover,
.slidesjs-play,
.slidesjs-play:hover {
    visibility: hidden;
    display: none;
}

.slidesjs-next,
.slidesjs-next:hover,
.slidesjs-previous,
.slidesjs-previous:hover {
    display: none;
}

/* Media Queries */
@media screen and (max-width: 768px) {
    /* Makes slider full-width on smaller screens */
    .content-banners .container {
        padding: 0;
    }
}

/* ================== */
/* END SLIDESHOW */
/* ================== */

/**********************************/
/* |   PRODUCT DETAIL ELEMENTS  | */
/**********************************/

/* Alerts */
/*  Alerts on the SERP and Product Pages
    Not to be confused with system alerts */
.vendorAlert-innerWrapper {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 24px 48px 16px 48px;
    margin: 24px 0 0 0;
    border: 5px solid #CCCCCC;
    border-radius: 6px;
    background-color: #fff;
}

.vendorAlert-imgContainer {
    padding-top: 8px;
    max-width: 30%;
}

.vendorAlert-img {
    max-width: 100%;
    height: auto;
}

.vendorAlert-body {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 30px;
    font-size: 16px;
    font-weight: 200;
    overflow-wrap: anywhere;
    width: 100%;
}

.vendorAlert-body h1,
.vendorAlert-body h1 span,
.vendorAlert-body p.alert-large,
.vendorAlert-body p.alert-large span {
    font-size: 40px;
}

.vendorAlert-body h2,
.vendorAlert-body h2 span,
.vendorAlert-body p.alert-medium,
.vendorAlert-body p.alert-medium span {
    font-size: 24px;
}

.vendorAlert-body p,
.vendorAlert-body p span,
.vendorAlert-body h4,
.vendorAlert-body h4 span,
.vendorAlert-body p.alert-small,
.vendorAlert-body p.alert-small span {
    font-size: 16px;
}

@media (min-width: 420px) {
    .vendorAlert-body {
        font-size: 15.5px;
    }
}

/***************************/
/* |   FOOTER ELEMENTS   | */
/***************************/
.bloomnationRep-img {
    margin: 20px;
    margin-left: 16px;
    height: 23px;
    width: 156px;
}

.bloomnation-logo-about-us-page {
    height: 72px;
    width: 160px;
    margin-top: 40px;
    margin-bottom: 24px;
}

.bloomnation-logo-social-page {
    margin-bottom: 24px;
    margin-top: 40px;
}

.about-us-page-premier-florist {
    margin: 10px 0 20px 0;
    text-align: center;
    white-space: normal;
}

.about-us-page-premier-florist p {
    margin: 2px 0;
}

.about-us-page-premier-florist a {
    color: #333;
}

.footer-social_media {
    background: var(--bn--light-gray);
    margin: 60px 0;
    padding: 25px 0;
}

.footer-infoItem .infoItem-header,
.footer-infoItem .infoItem-text {
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 200;
}

.footer-infoItem .infoItem-header {
    font-weight: 400;
}

.footer-infoItem .infoItem-header a {
    font-weight: 400;
    color: #333;
}

.social_media {
    font-weight: 300;
    white-space: nowrap;
}

.social_media i:first-child {
    font-size: 18px;
    color: #FFF;
    z-index: 99;
    padding: 0 0 0 3px;
}

.social_media i.fa-heart {
    font-size: 40px;
    color: #000;
    z-index: 99;
    text-indent: 0;
}

.social_media i.fa-facebook,
.social_media i.fa-google-plus {
    padding-top: 2px;
}

.social_media i.fa-yelp,
.social_media i.fa-pinterest,
.social_media i.fa-rss {
    padding-top: 1px;
}

.social_media i.fa-google-plus {
    padding-left: 7px;
}

.social_media img.social_media_link {
    position: inherit;
    z-index: 99;
    width: 1.2em;
    margin: 0 0 0.2em 0.2em;
}

/* temporary fix, until font-awesome v5 upgrade */
i.fa.fa-google-blue {
    width: 24px;
    height: 24px;
    background-image: url(../../../base/default/images/google-icon-blue.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 24px 24px;
}

i.fa.fa-google-white {
    width: 36px;
    height: 36px;
    background-image: url(../../../base/default/images/google-icon-white.png);
    background-repeat: no-repeat;
    background-position: 11px center;
    background-size: 18px 18px;
}

.social_media .row > a {
    text-align: center;
    text-decoration: none;
    margin: 10px 0;
}

.footer-socialName {
    display: inline-block;
    width: 70px;
    font-size: 12px;
    padding-right: 10px;
}

.social_media .fa-stack-2x {
    font-size: 2.5em !important;
    margin-top: -4px;
}

.facebook,
.twitter,
.instagram,
.pinterest,
.googleplaces,
.googleplus,
.yelp,
.youtube,
.tiktok,
.blog-rss {
    color: #000000;
}

.facebook i {
    color: #3b5998;
}

.twitter i {
    color: #00aced;
}

.instagram i {
    color: #517fa4;
}

.pinterest i {
    color: #cb2027;
}

.googleplaces i {
    color: #4885ED;
}

.googleplus i {
    color: #dd4b39;
}

.yelp i {
    color: #c41200;
}

.youtube i {
    color: #FF0000;
}

.tiktok i {
    color: #000000;
}

.blog-rss i {
    color: #FF6600;
}

.poweredbybloomnation {
    margin-top: 20px;
}

.member_of_bloomnation_wrapper:first-child {
    margin: 5px 10px 0 0;
}

.member_of_bloomnation_wrapper {
    display: block;
    text-align: center;
}

.member_of_bloomnation {
    display: inline-block;
    margin-top: 2px;
    color: green;
}

.footer-copyright {
    width: 100%;
    text-align: center;
    font-weight: 200;
    margin: 60px 0 40px;
}

.footer-extraInfoText {
    font-size: 11px;
    padding-bottom: 20px;
}

.awards-footer {
    margin-top: 40px;
}

.awards-footer img {
    width: auto;
    height: 90px;
    display: inline-block;
    margin-left: 8%;
}

.awards-footer img:first-child {
    margin-left: 0;
}

@media screen and (max-width: 991px) {
    .footer-infoItem--address,
    .footer-infoItem--hours {
        margin-top: 20px;
    }
}

/****************************/
/* |     MISC ELEMENTS    | */
/****************************/
.divider {
    border-bottom: 1px #CCC solid;
    position: relative;
    clear: both;
    margin-bottom: 10px;
}

.divider-padding {
    margin: 40px 0;
}

h3.divider {
    border-bottom: 0;
    position: relative;
    clear: none;
    margin-bottom: 0px;
    padding-right: 0 !important;
    margin-bottom: -54px;
}

h3.divider .divider {
    border-bottom: 1px #CCC solid;
    position: relative;
    clear: both;
    padding-top: 5px;
    margin-bottom: 10px;
}

.align-right {
    text-align: right !important;
}

.about_us-contact_box {
    background: var(--bn--light-gray);
    padding: 10px 20px 20px;
    margin: 5px 0;
}

.about_us-contact_box span {
    padding: 0 !important;
}

.about_us-designer_about {
    float: none !important;
    margin: 0 auto !important;
}

.about_us-designer_about img {
    margin-right: 30px;
    margin-bottom: 20px;
    float: left;
}

.about_us-designer_about small {
    display: block;
    margin-top: -10px;
    margin-bottom: 10px;
}

.about_us-designer_about img {
    background: var(--bn--light-gray);
    margin-top: 10px;
    border-radius: 500px;
    border: 0;
}

.about_us-designer_about h2 {
    padding-top: 25px;
}

.gallery-list-row {
    max-width: 720px;
    margin: 0 auto;
}

.gallery-list .divider {
    margin-bottom: 25px;
}

.gallery-list span {
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    position: absolute;
    top: 10px;
    left: 15px;
    color: #dd4b67;
    display: none;
    z-index: 999;
}

.gallery-list a {
    padding: 0 6px !important;
    margin-bottom: 20px;
}

.gallery-list a:hover img {
    opacity: 0.2;
}

.gallery-list a:hover span {
    display: block;
}

.gallery-list img {
    background: var(--bn--light-gray);
}

.gallery-list h3 {
    text-align: center;
    margin-top: 20px !important;
    font-size: 28px !important;
}

.cms-index-aboutus #content h1[itemprop=name] {
    margin-top: 20px;
    font-size: 28px;
}

.cms-index-gallery #content h1 {
    font-size: 28px;
}

.designerschoice-index-view #content h1 {
    font-size: 28px;
}

.cms-delivery-service-area #content h1[itemprop=name] {
    font-size: 28px;
}

.gallery-description {
    margin: 0 auto;
    margin-bottom: 20px;
    max-width: 720px;
    text-align: center;
    font-size: 13px;
    padding: 0 10px;
}

.gallery-linkWrapper {
    text-align: center;
}

.gallery-list span {
    font-size: 15px;
    color: #dd4b67;
}

#fancybox-overlay {
    z-index: 99999 !important;
}

.fancybox-title-inside-wrap {
    position: absolute !important;
    bottom: 15px;
    right: 15px;
    left: 15px;
    padding: 10px;
    background: rgba(0, 0, 0, .65);
    color: #fff;
}

#mapContainer {
    margin-bottom: 30px;
}

.about_us_info_span {
    width: 100%;
    float: left;
}

.category-products {
    clear: both;
    position: relative;
    z-index: 9999;
}

.listings-sorting_options select {
    display: inline;
    width: auto;
    min-width: 120px;
}

.empty_cart {
    padding: 40px 0;
}

.datetimepicker {
    padding: 15px;
    border: 1px #CCC solid;
    margin-left: -9px;
}

#LOADING {
    background: rgba(255, 255, 255, 0.7);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    font-size: 100px;
    padding-top: 270px;
    text-align: center;
}

.center-div {
    display: block;
    margin: 0 auto;
    float: none !important;
}

.right {
    text-align: right;
}

.noMarginLeft {
    margin-left: 0 !important;
}

.noPaddingLeft {
    padding-left: 0 !important;
}

.toolbar-bottom {
    position: relative;
    z-index: 99;
}

.zip-code-search {
    width: 180px;
    display: inline;
}

.itemInfo-name--desktop,
.itemInfo-name--mobile {
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.itemInfo-name--mobile {
    display: none;
}

/* Footer Image Block */
.footerImgs {
    padding: 0;
    text-align: center;
}

.footerImgs-itemsContainer,
.footerImgs-item,
.footerImg-wrapper {
    display: inline-block;
}

.footerImgs-item {
    vertical-align: top;
}

.footerImgs-itemsContainer {
    overflow: hidden;
}

.footerImg-wrapper {
    padding: 0 5px 5px;
}

.footerImg-text {
    display: block;
    margin-top: 3px;
}

/*************************/
/* |    CART STYLES    | */
/*************************/
.data-table td,
.data-table th,
.data-table > * {
    padding: 10px;
}

.cart h2 {
    margin: 40px 0 30px 0px;
}

.cart .sidebar .btn-success {
    width: 100%;
    text-align: center;
    padding: 15px 20px;
}

#shopping-cart-totals .row {
    padding: 12px 0;
}

#shopping-cart-totals span.deliveryDate {
    position: relative;
    display: inline-block;
    width: 100%;
}

#shopping-cart-totals .datepickr-calendar {
    max-width: 250px;
}

#shopping-cart-totals-table {
    width: 100%;
}

#shopping-cart-totals-table tr td {
    float: right;
    padding: 10px;
}

#shopping-cart-totals-table tr td:first-child {
    float: left;
}

#shopping-cart-totals-table tr#tax-info-row {
    display: block !important;
}

#shopping-cart-totals-table tr#total-exclude-tax {
    display: none;
}

#shopping-cart-totals-table tr#total-include-tax {
    display: block !important;
}

#shopping-cart-totals-table td.deliveryDate > a {
    text-decoration: underline;
}

#shopping-cart-totals .delivery-pickup-caption {
    margin-bottom: 10px;
}

#shopping-cart-totals .delivery-pickup-date-container {
    background-color: #FFFFFF;
    border: 1px solid var(--bn--light-gray);
    border-radius: 3px;
}

#shopping-cart-totals .delivery-pickup-date-block {
    background-color: #FAFAFA;
    color: #545454;
}

#shopping-cart-totals .review-delivery-date {
    float: left;
}

#shopping-cart-totals .calendar-icon-container {
    float: right;
}

#shopping-cart-totals .calendar-icon-container .fa-calendar {
    color: #545454;
}

#shopping-cart-totals .delivery-pickup-date-inner-container {
    height: 20px;
}

#shopping-cart-table {
    width: 100%;
    min-width: 300px;
    margin: 0;
}

@media (max-width: 767px) {
    #shopping-cart-table tr {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        border-top: 0;
    }

    .cart_product-image {
        position: absolute;
        border-right: 0 !important;
        border-bottom: 0 !important;
    }

    .cart_product-details {
        width: 100%;
        padding-left: 105px !important;
        min-height: 98px;
        border-bottom-color: #E9E9E9;
        border-left: 1px solid #E9E9E9 !important;
    }

    .cart_product-action {
        flex: 1 1 auto;
        text-align: right;
    }

    .cart_product-qty label {
        font-weight: 300;
        margin-right: 4px;
        font-size: 12px;
    }

    .cart_product-qty .qty {
        width: 42px;
        height: 32px;
    }

    .cart_product-price {
        min-width: 96px;
        border-left: 1px solid #E9E9E9 !important;
    }

    .cart_product-price,
    .cart_product-subtotal {

        display: flex;
        align-items: center;
    }

    .cart_product-subtotal {
        min-width: 108px;
    }

    .cart_actions {
        width: 100%;
    }
}

@media (max-width: 420px) {
    .cart_product-subtotal {
        min-width: 86px;
    }

    .cart_product-price {
        min-width: 76px;
    }
}


#shopping-cart-table .btn-continue {
    margin-right: 10px;
}

#shopping-cart-table h4.product-name a {
    text-decoration: underline;
}

.dropdown.cart-dropdown-size {
    width: 85px;
}

#shopping-cart-table .dropdown-size-title {
    text-transform: uppercase;
    margin: 20px 0px 0px 20px;
    font-size: 15px;
    font-weight: 100 !important
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu {
    min-height: 181px;
    width: 382px;
    padding: 0px;
    border: 1px solid #ccc;
    border-radius: 5px !important;
    overflow: visible;
    top: 35px;
    font-family: var(--bn--primary-font);
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .col-md-12 {
    margin: 15px 0 10px;
    padding: 0px;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .price-additional-text {
    font-size: 13px;
    padding-left: 22px;
    margin: 6px 0px -6px 0px;
    font-weight: 100 !important;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .size-list {
    margin: -15px 0 0;
    padding: 0px 10px;
    list-style-type: none;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .size-list li {
    padding: 0px 10px;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .size-list li .radio:hover {
    border: 1px solid #333;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .size-list .radio {
    width: 110px;
    height: 32px;
    border: 1px solid #808080;
    border-radius: 3px;
    line-height: 14px;
    margin: 0px;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .size-list .radio input[type="radio"] {
    margin-left: 5px;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .dropdown-menu-buttons {
    margin-top: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 7px;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .dropdown-menu-buttons .btn {
    position: relative;
    width: 112px;
    height: 32px;
    padding-top: 6px;
    padding-left: 14px;
    font-size: 15px;
    margin-bottom: 5px;
    border-radius: 3px !important;
    font-weight: 200 !important;
    outline: none;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .dropdown-menu-buttons .btn-default {
    color: #777;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .dropdown-menu-buttons .size-update {
    margin-right: 15px;
}

.btn.btn-primary.btn-lg.size-update {
    background: #808080;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .dropdown-menu-buttons .btn {
    border-color: #808080;
}

.btn.btn-lg.size-cancel {
    border-color: #808080;
}

#shopping-cart-table .cart-dropdown-size .shown-size:hover {
    cursor: pointer;
}

#shopping-cart-table .cart-dropdown-size .shown-size .fa-pencil {
    margin: 0 0 0 5px;
}

#shopping-cart-table .cart-dropdown-size .dropdown-nav__arrow-up {
    left: 50px;
    border-bottom: 15px solid #ccc;
}

#shopping-cart-table .cart-dropdown-size .dropdown-nav__arrow-up--overlay {
    left: 50px;
}

#shopping-cart-table .pickup-only {
    font-size: 13px;
    height: 42px;
    float: left;
    width: 100%;
}

#shopping-cart-table .pickup-only .pickup-only-icon {
    top: 10px;
}

#shopping-cart-table .pickup-only .pickup-only-label {
    font-size: 13px;
    margin-top: 12px;
}

.pickup-only-warning {
    text-align: center;
    border-radius: 3px;
    margin-left: 15px;
    background-color: #fffbe8 !important;
    border: solid 1px #ddc337 !important;
    padding: 10px 0 10px 0px !important;
    color: #555;
    font-size: 13px;
    min-height: 36px;
}

.product_details-innerContainer table.pickup-only-warning {
    display: table;
    width: 93%;
    margin: 0 17px 0 17px;
}

.pickup-only-warning-alert {
    height: 18px;
    font-weight: 200 !important;
}

.pickup-only-text {
    font-weight: 200 !important;
    padding: 12px 8px;
}

#instorePickupDialog {
    z-index: 9999 !important;
}


.no-checkout {
    opacity: 0.6;
    filter: alpha(opacity=60);
    pointer-events: none;
}

.shipping_information .pickup-only-icon {
    float: left;
    margin-right: 8px;
    margin-top: -7px;
}

/************************************/
/* | style for size radio buttons | */
/************************************/

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox {
    padding-left: 20px;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 5px;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #555555;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox input[type="checkbox"] {
    opacity: 0;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox input[type="checkbox"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox input[type="checkbox"]:checked + label::after {
    font-family: 'FontAwesome';
    content: "\f00c";
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox input[type="checkbox"]:disabled + label {
    opacity: 0.65;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox input[type="checkbox"]:disabled + label::before {
    background-color: #eeeeee;
    cursor: not-allowed;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox.checkbox-circle label::before {
    border-radius: 50%;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox.checkbox-inline {
    margin-top: 0;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox-primary input[type="checkbox"]:checked + label::before {
    background-color: #428bca;
    border-color: #428bca;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox-primary input[type="checkbox"]:checked + label::after {
    color: #fff;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox-danger input[type="checkbox"]:checked + label::before {
    background-color: #d9534f;
    border-color: #d9534f;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox-danger input[type="checkbox"]:checked + label::after {
    color: #fff;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox-info input[type="checkbox"]:checked + label::before {
    background-color: #5bc0de;
    border-color: #5bc0de;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox-info input[type="checkbox"]:checked + label::after {
    color: #fff;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox-warning input[type="checkbox"]:checked + label::before {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox-warning input[type="checkbox"]:checked + label::after {
    color: #fff;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox-success input[type="checkbox"]:checked + label::before {
    background-color: #5cb85c;
    border-color: #5cb85c;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .checkbox-success input[type="checkbox"]:checked + label::after {
    color: #fff;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio {
    padding-left: 15px;
}

#shopping-cart-table .cart-dropdown-size .radio label {
    position: relative;
    padding: 12px 0 0px 5px;
    font-size: 14px;
    font-weight: 100 !important;
    margin: 0;
    top: 9px;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 13px;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    width: 10px;
    height: 10px;
    left: 3px;
    top: 16px;
    margin-left: -20px;
    border-radius: 50%;
    background-color: #555555;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio input[type="radio"] {
    opacity: 0;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio input[type="radio"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio input[type="radio"]:checked + label::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio input[type="radio"]:disabled + label {
    opacity: 0.65;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio input[type="radio"]:disabled + label::before {
    cursor: not-allowed;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio.radio-inline {
    margin-top: 0;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio-primary input[type="radio"] + label::after {
    background-color: #428bca;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio-primary input[type="radio"]:checked + label::before {
    border-color: #808080;
    outline: none;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio-primary input[type="radio"]:checked + label::after {
    background-color: #808080;
    outline: none;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio-danger input[type="radio"] + label::after {
    background-color: #d9534f;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio-danger input[type="radio"]:checked + label::before {
    border-color: #d9534f;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio-danger input[type="radio"]:checked + label::after {
    background-color: #d9534f;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio-info input[type="radio"] + label::after {
    background-color: #5bc0de;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio-info input[type="radio"]:checked + label::before {
    border-color: #5bc0de;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio-info input[type="radio"]:checked + label::after {
    background-color: #5bc0de;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio-warning input[type="radio"] + label::after {
    background-color: #f0ad4e;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio-warning input[type="radio"]:checked + label::before {
    border-color: #f0ad4e;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio-warning input[type="radio"]:checked + label::after {
    background-color: #f0ad4e;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio-success input[type="radio"] + label::after {
    background-color: #5cb85c;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio-success input[type="radio"]:checked + label::before {
    border-color: #5cb85c;
}

#shopping-cart-table .cart-dropdown-size .dropdown-menu .radio-success input[type="radio"]:checked + label::after {
    background-color: #5cb85c;
}

/************************************/
/* | end style for size radio buttons | */
/************************************/

#couponCollapse {
    padding: 0;
}

#discount-coupon-form {
    width: 327px;
    margin-left: 10px;
}

#discount-coupon-form h2 {
    margin-bottom: 0px;
}

#discount-coupon-form * {
    font-weight: 200;
}

#discount-coupon-form .btn {
    clear: both;
    margin-top: 10px;
}

.cart .btn.btn-danger {
    background: var(--bn--light-gray) !important;
    color: #F00 !important;
    border: 0 !important;
}

.cart .btn.btn-danger:hover {
    color: #F00 !important;
}

.checkout-as {
    background: rgba(255, 255, 255, 0.8);
    position: fixed;
    z-index: 99999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: 100px;
    display: none;
}

.checkout-as .row {
    max-width: 900px;
    background: var(--bn--light-gray);
    padding: 40px 30px;
    position: relative;
}

.checkout-as .row > div > div {
    background: #FFF;
    padding: 20px 40px 40px;
    height: 330px;
}

.checkout-as h3 {
    font-weight: 200 !important;
    margin-bottom: 8px;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
}

.checkout-as .divider {
    margin-bottom: 15px;
}

.checkout-as small {
    text-transform: uppercase;
    font-size: 12px;
}

.checkout-as .btn {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    font-weight: 200;
    margin-top: 30px;
    text-transform: uppercase;
}

.contact_page_about img {
    float: left;
    width: 300px;
    height: auto;
}

/*******************************/
/* |     CHECKOUT STYLES     | */
/*******************************/
.checkout-page {
    margin-bottom: 50px;
}

.checkout-page .header-secure-badge > span {
    display: block;
    float: right;
}

.checkout-page .header-secure-badge > span {
    line-height: 14px;
    margin: 7px 5px 0 0;
    color: #BBB;
}

.norton-image {
    text-align: center;
    margin-top: 10px;
}

.norton-image image {
    width: 320px;
}

.checkout-page .header-secure-badge > span:first-child {
    font-size: 22px;
    margin: 0;
    line-height: 43px;
}

.checkout-page .row {
    max-width: 950px !important;
}

:root {
    --bn--checkout-label-weight: var(--bn--font-medium);
}

.checkout-page #header .row > div:first-child,
#checkout .sidebar {
    background: transparent !important;
}

#checkout .sidebar .sidebar-inner {
    background: var(--bn--light-gray);
    padding: 20px;
}

@media (max-width: 769px) {
    #checkout .sidebar .sidebar-inner {
        margin-bottom: 60px;
    }

    .btn-checkout-next-wrapper {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 12px;
        background: #fff;
        box-shadow: 0px 5px 8px #000;
        z-index: 1;
    }
}

.btn-checkout-next--bottom {
    margin-top: 20px;
}

@media (max-width: 769px) {
    .btn-checkout-next--bottom {
        display: none !important;
    }
}

.btn-checkout_mobile-total {
    display: none;
    justify-content: space-between;
    margin-bottom: 6px;
    font-weight: 400;
}

@media (max-width: 769px) {
    .btn-checkout_mobile-total {
        display: flex;
    }
}

.btn-checkout_subtotal-items {
    font-weight: 300;
}

.seemore-text {
    display: block;
    font-size: 12px;
    font-weight: 200 !important;
    padding-left: 5px;
    margin-bottom: 20px;
}

#checkout .sidebar .btn {
    width: 100%;
}

#checkout .sidebar .btn-success {
    padding: 15px 20px;
    font-weight: 200;
    font-size: 18px;
    border-radius: 3px !important;
    -webkitborder-radius: 3px !important;
    -moz-border-radius: 3px !important;
    -o-border-radius: 3px !important;
}

#checkout .sidebar .item {
    border-top: 1px #CCC solid;
    padding: 5px 1px;
    font-weight: 100;
    min-height: 85px;
}

#checkout .sidebar .item:first-child {
    margin-top: 8px !important;
}

#checkout .sidebar .item img {
    width: 80px;
    height: auto;
    position: absolute;
}

#checkout .sidebar .item span {
    font-size: 13px;
}

#checkout .sidebar .item span.item_info {
    font-weight: 300 !important;
    padding-top: 4px;
    font-size: 12px;
    display: block;
    line-height: 18px;
    margin-left: 90px
}

#checkout .sidebar .item span.item_info div {
    font-weight: 200;
}

#checkout .sidebar .item .item_info .product-name {
    font-size: 13px;
    margin-bottom: 5px;
    line-height: 14px;
}

#checkout .sidebar .item .item_info .product-price {
    margin-bottom: 0;
}

#checkout .sidebar .item .item_info .product-delivery-type {
    color: green;
}

#checkout .sidebar .item .item_info .product-delivery {
    margin-top: -5px;
}

#checkout .sidebar .item .item_info .product-delivery a {
    position: relative;
    float: right;
}

#checkout .sidebar .item .item_info .product-delivery a input {
    padding: 0;
    width: 100%;
    height: 100%;
}

#checkout .sidebar .item .item_info .product-delivery a .datepickr-calendar {
    bottom: 25px;
}

#checkout .sidebar .item input {
    display: inline;
    background: transparent;
    border: 0;
    width: 69px;
    height: 24px;
    font-weight: 200;
    padding-top: 3px;
}

#checkout .sidebar .totals {
    padding-top: 10px;
    font-weight: 200;
}

#checkout .sidebar .totals .divider {
    padding-top: 5px;
    margin-bottom: 10px;
}

#checkout .sidebar .error {
    color: #F00;
    font-size: 12px;
    display: block;
    text-align: center;
    margin-bottom: 10px;
}

#checkout .price-change-message {
    color: #0654AC;
    padding-left: 20px;
    font-weight: var(--bn--font-demibold);
    margin-bottom: 8px;
}

#checkout .price-change-message .fa-info-circle {
    font-size: 16px;
    margin-right: 4px;
    position: absolute;
    margin-left: -19px;
    margin-top: 1px;
}

#checkout .step-status {
    border: 1px var(--bn--light-gray) solid;
    margin-bottom: 15px;
}

#checkout .step-status .fa-stack {
    font-size: 12px;
    margin-top: -4px;
}

#checkout .step-status .fa-stack .fa-check {
    color: #FFF !important;
}

#checkout .step-status .fa-stack span {
    font-size: 12px;
    font-weight: 400 !important;
    font-family: var(--bn--primary-font);
}

#checkout .step-status > span {
    display: block;
    float: left;
}

#checkout .step-status > i {
    display: block;
    float: left;
    padding: 14px 0;
}

#checkout .step-status a {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    text-decoration: none !important;
    max-height: 42px;
}

#checkout .step-status a.active,
#checkout .step-status a.disabled {
    pointer-events: none;
    -webkit-pointer-events: none;
    -moz-pointer-events: none;
    -o-pointer-events: none;
}

#checkout .step-status a.active {
    color: #333;
}

#checkout .step-status a.disabled {
    color: #CCC;
}

#checkout .step-status a.verified {
    color: rgb(96, 176, 63) !important;
}

#checkout .form-group .control-label {
    font-weight: 200 !important;
    text-align: left !important;
    white-space: nowrap;
    font-family: var(--bn--primary-font);
}

#checkout .form-group br {
    clear: both;
}

#checkout input, #checkout select {
    max-width: 270px;
}

.ps-stripe-element--base {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    max-width: 270px;
    padding-left: 5px;
    height: 34px;
}

#checkout textarea {
    min-height: 100px;
}

#checkout .step h3 {
    font-size: 16px;
    padding-top: 10px
}

#checkout .step h3:first-of-type {
    padding-top: 0;
    margin-top: 0;
}

#checkout .step h3 i {
    font-size: 26px;
    display: block;
    float: left;
    margin-top: -7px;
    margin-right: 10px;
}

#checkout .step h3 i.pickup-only-icon {
    margin-top: -10px
}

#checkout .step {
    position: static;
    margin-bottom: 100px;
    width: 100%;
    display: none;
    padding: 12px;
}

#checkout .step:first-child {
    display: block;
}

#checkout .step label.error {
    font-size: 12px;
    color: #F00;
    font-weight: 200;
}

#checkout .step input.error,
#checkout .step select.error {
    border: 1px #F00 solid;
}

#checkout #reviewStep > div i {
    font-size: 26px;
    display: block;
    float: left;
    margin-top: -3px;
    margin-right: 10px;
}

#checkout .step h3 i,
#checkout #reviewStep > div i {
    color: #BBB;
}

#checkout #stripe_expiration,
#checkout #stripe_expiration_yr {
    width: 38.5%;
    display: inline;
}

#checkout .totals b {
    font-weight: 500 !important;
}

.checkout-page #header {
    min-height: 100px !important;
}

#checkout .secure_payment {
    background: var(--bn--light-gray);
    padding: 20px 25px;
    margin-bottom: 20px;
    position: relative;
}

#checkout .secure_payment .cart_types {
    padding-top: 5px;
}

#checkout .secure_payment .cart_types img {
    width: 40px;
    height: auto;
}

#checkout .secure_payment .cart_types img.disabled {
    opacity: 0.3;
}

#checkout .secure_payment .fa-stack {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: #AAA;
}

#checkout #f-checkout__details__date {
    pointer-events: none;
    -webkit-pointer-events: none;
    -moz-pointer-events: none;
    -o-pointer-events: none;
}

#checkout #checkout__details {
    padding-top: 7px;
}

#checkout small,
#checkout small * q {
    font-size: 11px;
}

#checkout #reviewStep div {
    margin-bottom: 20px;
}

#checkout #reviewStep div span .delivery-address-type {
    font-size: 12px;
}

#checkout #reviewStep div div {
    font-weight: 200;
    font-size: 16px;
    border-bottom: 1px #CCC solid;
    margin-bottom: 10px;
    padding-bottom: 5px;
}

#checkout #reviewStep div div a {
    font-size: 13px;
    float: right;
    margin-top: 9px;
}

#checkout #reviewStep div span {
    font-size: 17px;
    font-weight: 100 !important;
}

#checkout #reviewStep div span b {
    font-weight: 200 !important;
}

#checkout #reviewStep .card_field span {
    background: #F8F8EC;
    display: block;
    padding: 50px;
    text-align: center;
    font-size: 30px;
    font-style: italic;
    font-family: 'Bad Script', cursive;
    word-break: break-word;
}

#checkout #reviewStep .delivery_fields .pickup-only-icon {
    margin-top: -6px;
}

.checkout-success-charged {
    margin-top: 0px;
    margin-bottom: 10px;
    border-bottom: 1px #CCC solid;
    padding-bottom: 5px;
    text-align: center;
}

#checkout .item .product-upgraded {
    font-size: 12px;
    margin: 5px 0;
    font-weight: 600 !important;
}

.instructions-block {
    margin-top: 36px;
}

input.phone::-webkit-input-placeholder {
    color: #CCC;
}

input.phone:-moz-placeholder {
    color: #CCC;
}

input.phone::-moz-placeholder {
    color: #CCC;
}

input.phone:-ms-input-placeholder {
    color: #CCC;
}

.address-select {
    max-width: none !important;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s,
    box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,
    box-shadow ease-in-out .15s
}

.address-select:focus {
    border-color: var(--bn--focus-color);
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075),
    0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075),
    0 0 8px rgba(102, 175, 233, 0.6)
}

.address-select::-moz-placeholder {
    color: #999;
    opacity: 1
}

.address-select:-ms-input-placeholder {
    color: #999
}

.address-select::-webkit-input-placeholder {
    color: #999
}

.checkout_details_sale {
    background: #CCC !important;
    color: #555;
    font-weight: 200;
    margin: 5px 0 10px;
    border-radius: 3px !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    -o-border-radius: 3px !important;
}

.checkout_details_sale:hover {
    color: #333 !important;
}

.f-checkout_details_coupon-label {
    font-weight: 200;
    margin-bottom: 10px;
    cursor: pointer;
    border: 0;
    padding: 0;
}

.checkout-success h2 {
    text-align: center;
    margin: 50px 0;
    font-size: 50px;
    font-weight: 100;
    color: #999;
}

.checkout-success h2 b {
    display: block;
    font-size: 30px;
    font-weight: 200 !important;
}

.checkout-success h2 .fa-stack {
    font-size: 30px;
    color: green;
    margin-left: -60px;
    margin-top: -10px;
}

.checkout-success .card_field span {
    background: #F8F8EC;
    display: block;
    padding: 50px;
    text-align: center;
    font-size: 30px !important;
    font-style: italic;
    font-family: 'Bad Script', cursive;
    margin-bottom: 30px;
    text-transform: none !important;
}

.checkout-success .order_success_right > div {
    margin-bottom: 20px;
    margin-left: 1px;
    text-transform: uppercase;
}

.checkout-success .order_success_right > div i {
    margin-right: 13px;
}

.checkout-success .order_success_right > div span {
    font-size: 22px;
    font-weight: 100 !important;
}

.checkout-success .order_success_right .success-review-address {
    margin-left: 40px;
}

.checkout-success .order_success_right .success-review-address i {
    display: block;
    margin-left: -40px;
    float: left;
    margin-top: 7px;
}

.success-contact-block {
    text-align: center;
    margin-top: 40px;
    clear: both;
    margin-bottom: 80px;
}

.success-contact-block span {
    font-size: 18px;
    margin: 0 30px;
    text-transform: uppercase;
}

.checkout-success #reviewStep > div > div.payment_fields span,
.checkout-success #reviewStep > div > div.date_fields span {
    margin-top: 65px;
}

.checkout-success .success-other-images {
    float: left;
    margin-top: 10px;
    clear: left;
}

.checkout-success .success-other-images img {
    width: 25%;
    padding: 0 10px 10px 10px !important;
}

.submit_order:hover {
    background: #46942F !important;
}

.secure_checkout small {
    letter-spacing: 0.04em !important;
}

#f-checkout_details_coupon {
    max-width: 100% !important;
}

.lightgray * {
    color: lightgray !important;
}

.disabled {
    cursor: default;
    pointer-events: none;
    -webkit-pointer-events: none;
    -moz-pointer-events: none;
    -o-pointer-events: none;
}

.cms-terms-of-service .bar-quick_sort,
.cms-privacy-policy .bar-quick_sort,
.cms-terms-of-service-ca .bar-quick_sort,
.cms-privacy-policy-ca .bar-quick_sort,
.cms-accessibility-statement .bar-quick_sort {
    display: none;
}

.boldText {
    font-weight: 400 !important;
}

.gift-message-box-outer {
    position: relative;
    height: 136px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #ffffff;
}

.customize-step-label,
.gift-message-box-outer,
.sentiments-header {
    font-family: var(--bn--primary-font);
    font-weight: var(--bn--checkout-label-weight) !important;
    line-height: 26px;
    margin: 0;
}

.step-customize .divider {
    margin: 8px 0;
}

.step-customize #user-email {
    max-width: 100%;
}

.customize-step-header {
    font-size: 16px;
    font-weight: var(--bn--font-demibold) !important;
    line-height: 22px;
}

.customize-step-occasion-group {
    margin-bottom: 12px;
}

.customize-step-email-group {
    margin-bottom: 24px;
}

.sentiments-header,
.occasion-name {
    font-weight: 500 !important;
}

#gift_message_text {
    height: 85%;
    overflow: auto;
    padding: 8px;
    outline: none;
    margin-top: 1px;
}

#gift_message_text span {
    background: #ffa0a0;
}

#character_counter {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-right: 8px;
    font-size: 12px;
    line-height: 24px;
}

#character_count {
    font-weight: var(--bn--checkout-label-weight) !important;
    font-size: 12px;
    font-weight: var(--bn--font-medium) !important;
}

.circle-right, .circle-left {
    width: 44px;
    height: 0;
    padding-bottom: 44px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #F2F2F2;
    position: relative;
    border: none;
}

.circle-right.disabled-arrow:hover, .circle-left.disabled-arrow:hover {
    background: #F2F2F2;
}

.circle-right:before {
    content: '';
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    border: solid #696475;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 7px;
    margin: 12px 0 0 -7px;
}

.circle-left:before {
    content: '';
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    border: solid #696475;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 7px;
    margin: 12px 0 0 7px;
}

.circle-left.disabled-arrow:before {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    border: solid #C2C0C5;
    border-width: 0 3px 3px 0;
}

.circle-right.disabled-arrow:before {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    border: solid #C2C0C5;
    border-width: 0 3px 3px 0;
}

.occasion-arrow-back i {
    border: solid #696475;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.sentiments-header-block {
    display: inline-block;
}

.occasion-arrow-back-block {
    float: right;
    margin-top: 5px;
}

.occasion-arrow-back {
    cursor: pointer;
}

.suggest-message,
.suggest-message-container {
    margin-bottom: 16px;
}

.suggest-message-btn {
    color: #09011CDE;
    cursor: pointer
}

.suggest-message-btn:hover {
    color: #09011CDE;
}

.sentiments-block {
    border: 1px solid #BDBDBD;
    border-radius: 0 0 4px 4px;
    padding: 12px 16px 32px 24px;
    margin-top: -3px;
    background-color: #F2F2F2;
}

.sentiments-block button,
.sentiment-buttons button {
    font-size: 14px;
    font-weight: var(--bn--font-normal);
    line-height: 17px;
}

.sentiment-buttons {
    display: inline-block;
}

.scrollbox {
    overflow: auto;
}

.sentiment-buttons {
    visibility: visible;
}

.sentiment-btn {
    padding: 6px 16px;
    margin: 0 12px 12px 0;
    background: #FFFFFF;
    border: 1px solid #B6B6B6;
    border-radius: 4px;
    white-space: nowrap;
}

.sentiment-btn.active {
    border: 1px solid #61A253;
    color: #3B873E;
}

.sentiment-btn:focus-visible,
.occasion-control-btns button:focus-visible {
    border-color: var(--bn--focus-color);
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}

#f-gift-message-whole-signature:focus,
#user-email:focus,
#checkout .contentEditable-focus {
    border-color: var(--bn--focus-color);
    outline: 0;
    -webkit-appearance: none;
    webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}

.current-occasion-message {
    font-family: 'Kalam', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #424242;
    padding: 16px 0;
}

.occasion-control-btns {
    display: flex;
}

.previous-sentiment {
    text-align: left;
    max-width: 44px;
}

.next-sentiment {
    text-align: right;
    max-width: 44px;
}

.select-sentiment {
    width: 174px;
    text-align: center;
    margin: auto 16px;
}

.select-sentiment-message-btn {
    width: 100%;
    padding: 7px 0;
    background: #FFFFFF;
    border: 1px solid #61A253;
    border-radius: 4px;
}

.select-sentiment-message-btn:hover {
    color: #3B873E;
}

/* switcher & img */
#bg-body {
    background: #D2D2D0;
    height: 100%;
    left: 0%;
    position: absolute;
    top: 0%;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    display: none;
}

#bg-body > img {
    bottom: 0;
    left: 0;
    margin: auto;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

/* switcher loader */
#bg-body-loader {
    background: url(../../../../../js/bloomnation/adminhtml/catalog/flatgrid/lightbox/images/loading.gif) no-repeat scroll center center rgba(0, 0, 0, 0);
    height: 10px;
    left: 50%;
    margin: -5px 0 0 -40px;
    position: absolute;
    top: 50%;
    width: 80px;
}

#bg-body.loading #bg-body-loader {
    display: block !important;
    z-index: 9999;
}

/* switcher bullets */
#bg-body-navigation {
    position: fixed;
    left: 0;
    bottom: 100px;
    width: 100%;
    height: 15px;
    text-align: center;
    z-index: 1;
}

#bg-body-navigation > a {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ccc;
    margin: 0 5px;
}

#bg-body-navigation > a:hover,
#bg-body-navigation > a.active {
    background: #333;
    opacity: .7;
}

/* switcher nav */
#bg-body-prevnext-wrapper {
    position: fixed;
    left: 0;
    bottom: 20px;
    width: 100%;
    height: 72px;
    z-index: 1;
}

.bg-body-prevnext-link {
    display: block;
    width: 35px;
    height: 72px;
    position: absolute;
}

.product_links-innerContainer .social-box-title {
    float: left;
    margin: 40px 10px 0px 0px;
    color: #777;
}

.product_links-innerContainer .social-box {
    float: left;
    margin-top: 16px;
    padding: 19px 0px 10px 0;
}

.container-socialmedia-share .social .fa {
    font-size: 29px;
}

.my-account-mob {
    display: inline-block;
}

.itemListings--catalog .item-wrapper .itemInfo-wrapper .itemInfo-deliveryText span {
    font-size: 10px;
    margin-top: -2px;
    font-weight: 100;
}

.itemListings--catalog .icon-compare-like-outline {
    width: 11px;
    height: 10px;
    right: 4px;
    top: 5px;
}

.itemListings--catalog .icon-compare-like-fill {
    width: 11px;
    height: 11px;
    right: 5px;
    top: 6px
}

.itemListings--catalog .delivery_notice .fa-stack-1x {
    left: -1px !important;
}

.itemListings--catalog .item-wrapper .pickup-only-label {
    font-size: 10px;
    margin: 0px 0px 0px 5px;
}

.itemListings--catalog .item-wrapper .pickup-only-icon {
    width: 14px;
    float: left;
    margin: -3px 0px 0px 0px;
}

.datepickr-calendar td.selected-day {
    background-color: #00b551;
}

.datepickr-calendar td.selected-day .datepickr-day {
    color: #fff;
}

.cta-hover-overlay {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 9999;
    background: #fff;
}

.cta-hover-overlay:hover {
    opacity: .2;
}

.best-selling-badge {
    position: absolute;
    display: flex;
    width: 97px;
    height: 29px;
    left: 31px;
    top: 16px;
    justify-content: center;
    padding: 5px 0;
    border: 1px solid #565656;
    border-radius: 2px;
    background: #fff;
    z-index: 10;
}

.best-selling-badge span {
    color: #565656;
    font-size: 12px;
    font-weight: 800 !important;
    text-transform: uppercase;
}

/* Media Queries */

/* Min-width */
@media (min-width: 768px) {
    .navigation .dropdown:hover ul {
        display: block;
    }
}

/* Max-width */
@media (max-width: 1260px) {
    .cart-sidebar,
    .cart-formWrapper {
        width: 100%;
    }
}

@media (max-width: 520px) {
    .cart-formWrapper {
        padding: 0;
    }
}

.cart-formWrapper .messages li {
    list-style: none;
}

.cart-formWrapper ul.messages ul {
    padding-left: 0;
}

.cart-formWrapper ul.messages {
    padding: 8px;
    margin-bottom: 16px;
}

.cart-formWrapper ul.messages, .deliveryDate-error {
    background: #fcf8e3 !important;
}

.deliveryDate-wrapper .delivery-date-calendar > a {
    font-weight: bold !important;
    text-underline-position: under;
}

@media (max-width: 1010px) {
    .itemInfo-name--mobile {
        display: table-cell;
    }

    .itemInfo-name--desktop {
        display: none !important;
    }
}

@media (max-width: 997px) {
    #orderForm .orderForm-heading {
        margin-top: 59px;
    }
}

@media (max-width: 991px) {
    #shopping-cart-table .cart-dropdown-size .dropdown-menu .size-list {
        margin: 0;
    }

    #shopping-cart-table .cart-dropdown-size .dropdown-menu .dropdown-menu-buttons {
        display: block;
    }

    .discount-form {
        margin-bottom: 30px;
    }

    .note-msg {
        margin-top: 20px !important;
    }
}

@media (max-width: 950px) {
    .sentiment-buttons {
        display: flex;
    }

    .step-customize #f-gift-message-whole-signature {
        max-width: 100%;
    }
}

@media (max-width: 767px) {
    #orderForm .orderForm-heading {
        margin-top: 0;
    }

    #header .information-heading {
        text-align: center;
        margin-bottom: 60px;
    }

    #header .header-cart .cart_button {
        background: #808080;
        display: block;
        float: left;
        margin-right: 25px;
    }

    .my-account-mob {
        display: block;
    }

    .cart_button i, .cart_text, .my-account-button-text {
        color: #fff;
    }

    .nav-itemWrapper--navToggle {
        display: block;
    }

    .navigation li,
    .nav-toggle .nav-hide,
    .navigation.open .nav-show {
        display: none;
    }

    .navigation.open li {
        display: block;
    }

    .navigation.open .nav-hide {
        display: inline;
    }

    .navigation li button {
        margin: 0 auto;
    }

    .featured_listings_block .clearfix {
        display: none !important;
    }

    .checkout-page #header .row > div:first-child, #checkout > div:first-child {
        margin-left: 0;
    }

    .navigation > * {
        display: block;
    }

    .dropdown .dropdown-menu {
        width: 100%;
        margin: 0;
        padding: 0;
        position: relative;
        border: 0;
    }

    .custom_amount-slider {
        display: none !important;
    }

    #formPrice {
        display: block !important;
    }

    .navigation > * {
        clear: both;
    }

    .my-account-mob {
        float: none;
        margin: 30px auto 0px;
    }

    .itemInfo-deliveryText .fa-stack {
        font-size: 7px;
    }

    .itemInfo-deliveryText span {
        font-size: 10px;
        font-weight: 100 !important;
    }

    .icon-compare-like-fill {
        right: 5px;
        top: 7px;
    }

    .product_details-orderForm .product_links-innerContainer {
        margin-top: 0px;
        border: 0px solid #ccc;
        height: 100%;
    }

    .product_details-orderForm .product_details-innerContainer {
        border: 0px solid #ccc;
    }

    .product_links-innerContainer .social-box-title {
        margin: 40px 14px 0px 35px;
    }

    .product_links-innerContainer .social-box {
        padding: 20px 0px 20px 0;
    }

    #shopping-cart-table .cart-dropdown-size .dropdown-menu .size-list .radio {
        width: auto;
    }

    #shopping-cart-table .cart-dropdown-size .dropdown-menu .size-list li {
        padding: 5px 10px;
    }

    #shopping-cart-table .cart-dropdown-size .dropdown-menu .price-additional-text {
        width: 90%;
        margin: 12px 0px 10px 0px;
        float: left;
    }

    #shopping-cart-table .cart-dropdown-size .dropdown-menu .dropdown-menu-buttons .btn {
        float: left;
        font-weight: 100;
        max-width: 90%;
        margin: 0px 0px 10px 0px;
        width: 100%;
    }

    #shopping-cart-table .cart-dropdown-size .dropdown-menu .dropdown-menu-buttons {
        margin-left: 2px;
    }

    #shopping-cart-table .cart-dropdown-size .dropdown-menu .dropdown-menu-buttons .btn-default {
        margin-left: 0px;
    }

    .size-cancel:hover {
        color: #afafaf !important;
    }

    #shopping-cart-table .cart-dropdown-size .dropdown-menu {
        height: 330px;
        width: 213px;
        position: absolute;
        z-index: 99999;
    }

    .itemInfo-wrapper .deliveryText-background {
        background: none;
    }

    .floating-like-button > .content > .bn-heart-wrapper,
    .floating-like-button > .content > .liked-items-number {
        transform: translate(-35px, 0);
        -webkit-transform: translate(-35px, 0);
        -moz-transform: translate(-35px, 0);
        -o-transform: translate(-35px, 0);
        -ms-transform: translate(-35px, 0);
    }

    .floating-like-button > .content > .view-all-button {
        right: 0px;
        opacity: 1;
        transform: translate(-0.5em, 0);
        -webkit-transform: translate(-0.5em, 0);
        -moz-transform: translate(-0.5em, 0);
        -o-transform: translate(-0.5em, 0);
        -ms-transform: translate(-0.5em, 0);
    }

    .header-cart {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    #onestepcheckout-form .form-group br {
        display: none;
    }

    #checkout .step {
        margin-bottom: 15px;
    }

    div#checkout-accounts-wrapper--js .form-group:nth-child(2) {
        margin-bottom: 0;
    }

    .customize-step-occasion-group {
        margin-bottom: 4px;
    }
}

@media (max-width: 520px) {
    #instorePickupDialog .modal-footer {
        padding: 19px 2px 20px;
        text-align: center;
    }

    #instorePickupDialog .modal-footer button {
        font-size: 13px;
    }

    #instorePickupDialog .modal-footer button.btn-proceed-checkout {
        margin-right: 10px;
    }

    .listings-sorting_options {
        margin-top: 20px;
    }
}

@media (max-width: 414px) {
    .listings {
        padding-left: 0;
        padding-right: 9px;
    }

    .itemInfo-name--mobile {
        display: block;
        width: 150px;
        position: relative;
        top: -9px;
        font-size: 11px;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        overflow: hidden;
    }

    .itemInfo-price, .itemInfo-priceContainer .price {
        display: block;
        position: absolute;
        left: 13px;
        margin-top: 3px;
        font-size: 11px;
        padding-left: 0;
    }

    .sidebar-best_selling .item_listing-information span.itemInfo-price {
        position: relative;
        margin-top: 0;
        left: 0;
        font-size: 12px;
    }

    .icon-compare-like-outline,
    .icon-compare-like-fill {
        width: 10px;
    }

    .icon-compare-like-fill {
        right: 5px;
        top: 7px;
    }

    .floating-like-button {
        display: none !important;
    }

    .item {
        padding: 0 6px;
    }

    .delivery_notice_msg {
        position: relative;
        right: 5px;
    }

    .item-wrapper .itemInfo-wrapper .itemInfo-deliveryText span {
        font-size: 11px;
    }

    .best-selling-badge {
        left: 22px;
    }
}

.shipping_information-fullfilment,
.checkout_payment-information,
.checkout_review {
    border: 1px var(--bn--border-color-light) solid;
}

.shipping_information_block {
    padding: 20px 0 0 0;
}

.shipping_information-fullfilment {
    padding: 20px 0 10px;
    margin-bottom: 16px;
}

.checkout_payment-information {
    padding: 20px 0;
}

.checkout_review {
    padding: 20px 0;
}

@media (max-width: 375px) {
    .itemInfo-name--mobile {
        width: 135px;
    }

    #instorePickupDialog .modal-footer button.btn-proceed-checkout {
        margin-right: 0;
    }

    .shipping_information_block .calendar_date_input_wrapper:after {
        margin-left: 225px !important;
    }
}

@media (max-width: 320px) {
    .itemInfo-name--mobile {
        width: 110px;
    }

    .item-wrapper .itemInfo-wrapper .itemInfo-deliveryText span,
    .delivery_notice_msg {
        font-size: 9px;
    }

    .shipping_information_block .calendar_date_input_wrapper:after {
        margin-left: 100px !important;
    }
}


/* =========================== */
/*  Product Detail Repolish  */
/* =========================== */

.alert {
    font-family: avenir, sans-serif !important;
    font-weight: 100 !important;
    padding: 0;
    margin-bottom: 10px;
    background: none;
    border: 0;
    text-align: center;
}

.alert-notification {
    text-align: left;
}

.product_details-innerContainer {
    border: 1px solid #ccc;
    background-color: #fff;
}

.product_links-innerContainer {
    margin-top: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    height: 96px;
    font-family: var(--bn--primary-font);
}

.product_links-innerContainer .icon-compare-like-outline {
    position: relative;
    width: 17px;
    height: 14px;
    top: auto;
    right: auto;
    margin-top: 1px;
    margin-right: 2px;
}

.product_links-innerContainer .icon-compare-liked {
    display: inline-block;
    margin: 5px 0 0 7px;
}

.product_links-innerContainer .icon-compare-liked svg {
    width: 17px;
    height: 14px;
}

.product_links-innerContainer .icon-compare-liked path {
    fill: #f6b094;
}

.productInfo--desktop .productInfo-description,
.productInfo--desktop .productInfo-subPolicy,
.productInfo--desktop .productInfo-storyIcons {
    margin: 20px 0 20px 0;
}

.productInfo-description,
.productInfo-subPolicy,
.productInfo-additionalInformation,
.productInfo-storyIcons {
    border: 1px solid #aaaaaa;
    float: none;
}

.productInfo-storyIcons {
    text-transform: capitalize;
    text-align: center;
    color: #777;
    letter-spacing: .1em;
}

.productInfo-storyIcons > .productInfo-storyIcon {
    display: inline-block;
    vertical-align: top;
    margin: 0 15px;
}

.productInfo-storyIcons > .productInfo-storyIcon p {
    padding-bottom: 0;
}

.productInfo-storyIcons > .productInfo-storyIcon > img {
    max-height: 80px;
    padding-bottom: .5em;
    display: inline-block;
}

/* Order Form */
#orderForm {
    margin-bottom: 20px;
}

.product-header {
    display: none;
    margin-bottom: 15px;
    padding: 0 10px;
}

.productView .product-name {
    margin-top: 0;
    margin-bottom: 0;
    color: #666;
    font-size: 25px;
    line-height: 1.2;
    text-align: center;
}

.product-descriptionWrapper {
    position: relative;
    margin-top: 10px;
    overflow: hidden;
    color: #444;
}

.product-descriptionLink {
    white-space: nowrap;
}

.to-order-box,
.orderForm-checkout {
    padding: 0 20px 20px;
}

.to-order-box {
    padding: 10px;
    margin-bottom: 10px;
}

#orderForm .orderForm-heading {
    display: block;
    margin-bottom: 4px;
    text-align: center;
    text-transform: uppercase;
    color: #666;
    font-size: 14px;
    font-weight: 100 !important;
}

#orderForm .to-order-box .orderForm-heading {
    display: none;
}

#orderForm .orderForm-price {
    -webkit-transition: color .35s ease;
    -moz-transition: color .35s ease;
    -ms-transition: color .35s ease;
    transition: color .35s ease;
}

#orderForm .orderForm-price.isChanged,
#orderForm .price-additional-text.isChanged {
    color: #00B551;
    -webkit-transition: color .35s ease;
    -moz-transition: color .35s ease;
    -ms-transition: color .35s ease;
    transition: color .35s ease;
}

/* Arrangement Size */
#orderForm .btn.active > div {
    box-shadow: inset 2px 2px #548f35,
    inset -2px -2px #548f35;
}

#orderForm .deliveryBtn-innerContainer {
    background: #fff !important;
    border: 1px #CCC solid;
    line-height: 10px;
    padding: 20px 10px;
}

#orderForm .deliveryBtn-text {
    display: block;
    font-size: 12px;
    font-weight: 300;
    clear: both;
    font-weight: 200 !important;
}

#orderForm .orderForm-price {
    margin: 0;
    font-size: 32px;
    text-align: center;
    color: #666;
}

#orderForm .orderForm-arrangement_size .up-sell-list {
    padding: 0;
    margin: 0;
    margin-top: 6px;
}

#orderForm .orderForm-delivery_date .btn.disabled {
    color: #bbb;
}

/* Delivery Date Modifier */
.btn-order-date {
    display: none;
}

.deliveryDate-btnWrapper {
    width: 100%;
    padding: 0;
    list-style: none;
}

#orderForm .btn-order-date-viewmore {
    display: block;
    float: none;
    width: 100%;
    max-width: 265px;
    padding: 0;
    margin: 0 auto;
}

#orderForm .deliveryBtn-innerContainer--calendar {
    position: relative;
    padding: 15px;
    line-height: 1.1;
    word-break: break-word;
}

.btn-order-date-viewmore .deliveryBtn-innerContainer--calendar {
    min-height: 42px;
    padding: 15px 10px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

#orderForm .deliveryBtn-text--calendar {
    display: block;
    padding-top: 0;
    text-align: center;
    text-transform: uppercase;
    color: #666;
    font-size: 13.5px;
    font-weight: 100 !important;
}

#orderForm .deliveryBtn-text--calendar.isActive {
    line-height: 1;
    font-size: 16px;
    font-weight: 300 !important;
    color: #333;
}

#orderForm .deliveryBtn-text--mobile {
    display: none;
}

#orderForm .deliveryBtn-innerContainer {
    height: auto;
    font-size: 12px;
    font-weight: 200;
}

#orderForm .deliveryBtn-innerContainer--calendar .fa-calendar {
    position: absolute;
    display: inline;
    top: 13px;
    left: 17px;
    padding-right: 10px;
    margin-left: -5px;
    color: #999;
    font-size: 20px;
}

#orderForm .deliveryBtn-innerContainer--calendar .fa-chevron-down {
    position: absolute;
    right: 0;
    padding: 16px 15px;
    top: 0;
    transition: 1s all ease;
    color: #999;
}

.fa-chevron-down.isActive {
    transition: 1s all ease;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -180deg);
    transform: rotate3d(0, 0, 1, -180deg);
}

#orderForm .price-additional-text,
.bloom-delivery-text {
    font-size: 13px;
}

/* Zip Code Validation */
.bloom-delivery-info {
    display: block;
    margin-bottom: 5px;
}

.bloom-delivery-type-code {
    display: inline-block;
}

.delivery-check-btn {
    display: inline-block;
    border: 1px solid #d8227d;
    color: #d8227d;
    -moz-border-radius: 3px;
    border-radius: 3px;
    cursor: pointer;
    padding: 4px 8px 4px;
    background: none;
}

.delivery-check-btn:hover {
    color: #d8227d;
}

.zip-input {
    padding: 4px 6px 4px;
    width: 80px;
    vertical-align: top;
    border: 1px solid #aaaaaa;
    -moz-border-radius: 2px;
    border-radius: 2px;
    color: #333;
}

/* Order Checkout Buttons */
.product_details-orderForm .bloom-sameday_info .hours,
.product_details-orderForm .bloom-sameday_info .hours * {
    font-weight: 400 !important;
}

.buttonContainer--addtoCart {
    padding: 0;
}

.buttonContainer--order {
    padding: 0;
    width: 100%;
}

/* Cross Sell */
.orderForm-additionalGiftContainer {
    margin-top: 20px;
}

/* About Us */
.product_details-about_heading {
    font-size: 18px;
    font-weight: 100 !important;
    letter-spacing: 1px !important;
    text-align: center;
    text-transform: uppercase;
    padding: 5px 0;
}

.about-designer-image img {
    aspect-ratio: 1 / 1;
    background: var(--bn--light-gray);
    display: block;
    height: auto;
    margin: 0 auto;
    margin-top: -1px;
    max-width: 100%;
    position: relative;
    width: 100%;
    z-index: -1;
}

.about-designer-image {
    border-radius: 50%;
    border: 1px #CCC solid;
    display: inline-block;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 123px;
    z-index: 99;
}

.about_us-about_designer {
    float: none !important;
    margin: 0 auto;
}

.product_details-about-designer {
    text-align: center;
    margin-top: 5rem;
}

.product_details-about-designer h3 {
    font-size: 25px;
    font-weight: 100 !important;
    padding-top: 7px;
    margin-top: 0;
}

.product_details-about-designer span {
    display: inline-block;
    font-weight: 200;
    max-width: 65ch;
}

@media (max-width: 767px) {
    #orderForm .orderForm-delivery_date .deliveryBtn-innerContainer--calendar {
        height: 65px;
        padding-top: 21px;
    }

    #orderForm .to-order-box {
        margin-bottom: 0;
    }

    #orderForm .to-order-box .orderForm-heading {
        display: block;
    }

    .btn-order-date {
        width: 33.333333%;
    }

    .deliveryBtn-text {
        padding-top: 5px;
    }

    .btn-order-date:nth-of-type(3) {
        padding-right: 0;
    }

    .order-header {
        display: none;
    }

    .product-header {
        display: block;
    }

    #orderForm .deliveryBtn-text--mobile {
        display: block;
    }

    #orderForm .deliveryBtn-text--desktop {
        display: none;
    }

    #orderForm .deliveryBtn-text--calendar {
        line-height: 1.4;
    }

    #orderForm .deliveryBtn-text--calendar.isActive {
        line-height: 1.2;
    }

    #orderForm .deliveryBtn-innerContainer--calendar .fa-chevron-down {
        padding: 24px 14px;
    }

    #orderForm .deliveryBtn-innerContainer--calendar .fa-calendar {
        top: 21px;
    }

    .productInfo-subPolicy {
        display: none;
    }

    .productInfo-description, .productInfo-subPolicy, .productInfo-storyIcons {
        float: left;
    }

    .productInfo-storyIcons > .productInfo-storyIcon {
        margin: 0 5px;
    }

    .productInfo-storyIcons > .productInfo-storyIcon p {
        font-size: 85%;
    }

    .productInfo--desktop {
        display: none;
    }

    .productInfo--mobile,
    .productInfo-subPolicy--mobile {
        display: block;
    }

    #checkout div.sidebar {
        margin-top: 0;
    }
}

@media (max-width: 437px) {
    .product-header .product-name--mobile {
        font-size: 21px;
    }

    #orderForm .orderForm-delivery_date .btn {
        padding: 5px;
    }

    .zip-input {
        margin-bottom: 5px;
    }

    #orderForm .deliveryBtn-innerContainer--calendar {
        padding: 20px 10px;
    }

    .sameday-description span {
        font-size: 12px;
    }
}

/*******************/
/* GLOBAL MESSAGES */
/*******************/
#notification-bar {
    position: relative;
    text-align: center;
    background-color: #3b4146;
    color: #ffffff;
    line-height: normal;
    overflow: hidden;
}

#notification-bar #notification {
    margin: 0 auto;
    max-width: 75%;
    position: relative;
    text-align: center;
}

#notification-bar #notification .notification-content ul {
    list-style: none;
    margin: 15px auto;
    padding-left: 0;
}

#notification-bar #notification .notification-content ul li {
    line-height: 17px;
    word-wrap: break-word;
    text-align: center;
}

#notification-bar .notification-close {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 50%;

    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);

    cursor: pointer;
    display: inline-block;
    position: absolute;
    right: 8px;
}

#notification-bar .notification-close .btn-remove {
    width: 24px;
    height: 24px;
    cursor: pointer;
    text-decoration: none;
}

#notification-bar .notification-close .btn-remove svg {
    width: 24px;
    height: 24px;
    transition: transform .5s ease-in-out;
}

#notification-bar .notification-close .btn-remove svg > path {
    stroke: #3b4146;
}

#notification-bar .notification-close .btn-remove svg:hover {
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    color: #FFF;
    text-decoration: none;
}

#notification-bar .notification-close .btn-remove svg:hover > circle {
    fill: #707070;
    stroke: #707070;
}

#notification-bar .notification-close .btn-remove svg:hover > path {
    stroke: #FFF;
}

@media (min-width: 320px) {
    #notification-bar #notification .notification-content {
        font-size: 11px;
    }
}

@media (min-width: 550px) {
    #notification-bar .notification-close {
        right: 20px;
    }
}

@media (min-width: 768px) {
    #notification-bar #notification .notification-content {
        font-size: 12px;
    }
}

@media (min-width: 768px) {
    #notification-bar #notification .notification-content {
        font-size: 13px;
    }
}

/*************************/
/* GLOBAL MESSAGES (END) */
/*************************/

.special-instructions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 20px;
    padding: 10px 20px;
    background: #f7f7f7;
}

.special-instructions__svg {
    margin-right: 10px;
}

.special-instructions__svg svg {
    vertical-align: middle;
    width: 20px;
    height: 20px;
}

/* checkout-accounts.css overrides: */
#content strong {
    font-weight: bold !important;
}

#checkout h3.create-login-header {
    font-weight: bold !important;
    font-family: inherit !important;
    font-size: 18px !important;
}

#content .success-text {
    font-size: 19px;
    font-weight: 200 !important;
    text-transform: none;
}

#content .success-text-confirm {
    margin: 10px 0 20px 0;
    font-size: 21px;
}

#content .success-delivery {
    text-align: center;
    font-size: 13px;
}

#content .success-delivery img {
    margin-right: 5px;
    width: 15px;
}

#content .success-delivery span {
    vertical-align: middle;
    text-align: center;
    font-size: 13px;
}

.checkout-onestep-success #content {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background: transparent;
    max-width: 960px;
    margin: 50px auto;
}

#checkout .create-an-account p,
#checkout .login-account p {
    font-size: 12px !important;
}

.checkout-onestep-success .success-blocks {
    padding: 30px 15px !important;
}

@media only screen and (min-width: 768px) {
    .checkout-onestep-success .success-blocks {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .circle-right:hover, .circle-left:hover {
        background: rgb(229, 229, 231, 0.7);
    }
}

@media only screen and (min-width: 961px) {
    .sentiment-btn:hover {
        color: #3B873E;
    }
}

.checkout-onestep-success .success-block {
    margin: 0 15px !important;
    padding: 30px;
    background: #fff;
}

@media only screen and (max-width: 768px) {
    .checkout-onestep-success .success-block {
        margin-bottom: 0 !important;
        padding: 20px 15px;
    }
}

#content .success-block hr {
    border-top-color: #ccc;
}

.checkout-onestep-success #checkout .additional-options__header {
    font-size: 20px;
    font-weight: bold !important;
    font-family: inherit;
}

.checkout-onestep-success #checkout .account-button {
    margin: 25px auto;
}

.card_field span {
    background: #F8F8EC;
    display: block;
    padding: 25px;
    text-align: center;
    font-size: 24px !important;
    font-style: italic;
    font-family: 'Bad Script', cursive;
    margin-bottom: 30px;
    text-transform: none !important;
    border: 1px solid #ddd;
}

.recipient-info p {
    margin: 5px 0;
    font-size: 13px !important;
}

.additional-options__continue-shopping hr {
    margin: 30px 0;
}

.additional-options__continue-shopping p {
    line-height: 1.5;
    font-size: 14px;
}

.additional-options__submit {
    width: 200px;
    margin: 15px 0;
    padding: 8px;
    border: none;
    border-radius: 3px !important;
}

.account__bold {
    display: block;
    margin-bottom: 5px !important;
}

#content .product-image {
    max-width: none;
    width: 100%;
}

#content .success-checkmarkContainer {
    margin-bottom: 20px;
    text-align: center;
}

small.recipient-phone-help-msg, small.email-help-msg {
    margin: 7px 0 0 0;
    display: block;
    position: relative;
    padding-left: 24px;
    height: 20px;
    line-height: 20px;
    font-family: sans-serif;
    font-style: italic;
}

small.email-help-msg {
    padding-left: 0;
}

#onestepcheckout-form .recipient-phone-help-msg svg {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    left: 10px;
}

#checkout .order-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media only screen and (max-width: 768px) {
    #checkout .order-info {
        margin-bottom: 20px;
    }
}

#checkout .order-info .title {
    margin: 0 0 7px 0;
}

#checkout .order-info svg {
    width: 18px;
    margin-right: 15px;
}

#checkout .order-info i {
    margin-right: 15px;
    font-size: 16px;
}

#checkout .order-info svg path {
    fill: #333;
}

.tipping_aply_disabled {
    opacity: 50%;
    pointer-events: none;
}

.tipping_aply_enabled {
    opacity: 100%;
    pointer-events: auto;
}

.tip-info {
    background-color: #8D8D8D;
    position: absolute;
    display: none;
    color: white;
    width: 260px;
    z-index: 3;
    border-radius: 15px;
    padding: 10px;
    text-align: left;
}

#tipping-block .error {
    color: #F00;
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
    margin-top: 55px;
    text-align: left;
}

.tipping-tooltip-icon {
    font-size: 15px;
    color: #8d8d8d;
    margin-bottom: -4px;
}

.bn-tipping a:hover {
    color: #AF9F63 !important;
    background-color: #fff !important;
}

.bn-tipping a.active {
    background-color: #ddd !important;
    color: #2a6496 !important;
    border-color: #ddd !important;
}

.bn-tipping a {
    width: 70px;
    text-align: center;
    font-weight: 200 !important;
    cursor: pointer;
}

.bn-tipping li:last-child a {
    width: 100px;
    text-align: center;
}

ul.bn-tipping.pagination {
    margin: 0px;
}

#f-other-tip-block {
    margin-top: 17px;
    display: none;
}

#f-other-tip-block input {
    width: 210px;
    display: inline;
    float: left;
}

.tip-vendor-florist {
    margin-left: 11%;
}

.tip-vendor-liquor {
    margin-left: 19%;
}

.input-icon {
    position: relative;
}

.input-icon > span {
    position: absolute;
    display: block;
    transform: translate(0, 35%);
    -webkit-transform: translate(0, 37%);
    top: 50%;
    pointer-events: none;
    width: 20px;
    text-align: center;
    font-style: normal;
}

.input-icon > input {
    padding-left: 15px;
    padding-right: 0;
}

.input-icon-right > span {
    right: 0;
}

.input-icon-right > input {
    padding-left: 0;
    padding-right: 15px;
    text-align: right;
}

.delivery_notice .fa-stack-2x {
    font-size: 2.2em;
    left: -3px
}


/*********************************************
Premium Sites - SMS Marketing Component
*********************************************/

.sms-marketing {
    background: #ffffff;
    padding: 20px;
    margin-left: -19px;
    margin-right: -19px;
}

.sms-marketing .form-group {
    position: relative;
}

.sms-marketing_option input {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
}

.sms-marketing_option .control-label {
    font-weight: 400;
    margin-right: 10px;
}

.sms-marketing_option label {
    position: relative;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: #000000;
}

.sms-marketing_option input:focus + label:before {
    border-color: var(--bn--focus-color);
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.sms-marketing_option label:before {
    content: '';
    apperance: none;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 2px;
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px;
}

.sms-marketing_option input:checked + label:before {
    background: #000000;
}

.sms-marketing_option input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 5px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0px 2px 2px 0;
    transform: rotate(45deg);
}

.sms-marketing_phone {
    display: flex;
    flex-wrap: wrap;
    margin-left: 28px;
}

.sms-marketing_phone .control-label {
    margin-right: 10px;
    padding-top: 6px;
    font-weight: 400;
    flex: 0 1 auto;
    color: #000000;
    font-size: 14px;
}

.sms-marketing_phone.has-error .control-label {
    color: revert;
}

.sms-marketing_phone input {
    flex: 1 1 min-content;
}

.sms-marketing_phone.has-error .form-control {
    border-color: #E31B0C;
}

.sms-marketing_phone .help-block {
    flex: 1 1 100%;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    margin-left: 55px;
}

.sms-marketing_disclosure {
    margin-left: 28px;
    color: #424242;
    margin-bottom: 0;
}

.sidebar .sms-marketing_disclosure a,
.sidebar .sms-marketing_disclosure a:hover {
    text-decoration: underline;
    color: #424242;
}

.sms-marketing_phone .help-block {
    visibility: hidden;
}

.sms-marketing_phone.has-error .help-block {
    visibility: visible;
    color: #E31B0C;
}

.sms-marketing .divider {
    display: none;
}

/*********************************************
Premium Sites - My account dropdown
*********************************************/

.my-account-button,
.nav-item--dropdown {
    background: none;
    border: none;
}

.my-account-button {
    padding: 0
}

.dropdown-toggle:hover {
    cursor: pointer;
}

.dropdown-toggle:focus,
.nav-item--dropdown:focus {
    outline: revert
}

@media (max-width: 767px) {
    .dropdown-toggle {
        width: 100%;
    }
}

/*********************************************
Premium Sites - Dropdown Component
*********************************************/

.dropdown-content--visible {
    display: block;
}


/*********************************************
Premium Sites - Checkout - Payment Step
*********************************************/

.payment-method-tabs,
.payment-method_card-tab {
    display: flex;
    align-content: space-between;
    margin-top: 16px;
}

.payment-method-tab,
.card-tab {
    width: 100%;
    padding: 0.75rem;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    border: 2px solid rgba(9, 1, 28, .23);
    border-radius: 4px;
    transition: border .15s ease;
}

.card-tab {
    margin-top: 20px
}

.payment-method-tab:first-child,
.card-tab:first-child {
    margin-right: 16px;
}

.payment-method-tab--ghost {
    opacity: 0;
}

.payment-method-tab--selected,
.card-tab--selected {
    border-color: #317AF6;
}

.payment-method-tab_label {
    font-family: var(--bn--primary-font);
    font-weight: 500 !important;
    color: rgba(9, 1, 28, 0.87);
    white-space: nowrap;
}

.payment-method-tab--selected .payment-method-tab_label,
.card-tab--selected .payment-method-tab_label {
    color: #317AF6;
}

.payment-method-tab_icon {
    width: 100%;
    fill: #696475;
}

.payment-method-tab--selected .payment-method-tab_icon,
.card-tab--selected .payment-method-tab_icon {
    fill: #317AF6;
}

.payment-method {
    display: none;
}

.payment-method.payment-method--active {
    display: block;
}

.payment-method .payment-method_content {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear .15s, opacity 300ms .15s;
}

.payment-method.payment-method--active .payment-method_content {
    visibility: visible;
    opacity: 1;
}

.payment-method_content--wallet {
    border: 1px solid rgba(9, 1, 28, 0.23);
    border-radius: 4px;
    background: #ffffff;
    padding: 12px;
    margin: 15px 0 8px 0;
}

.payment-method_content--wallet h4 {
    font-size: 16px;
}

#checkout .payment-method_content--wallet .divider {
    margin: 12px 0;
    border-bottom: 1px solid #CFCFD4;
}

.payment-method_content--wallet p {
    color: rgba(105, 100, 117, 1);
}

.payment-method_content--card {
    padding-bottom: 32px;
    margin: 16px 0 12px;
    border-bottom: 1px solid #696475;
}

.payment-method_content--saved-cards {
    margin-top: 12px;
}

.payment-method_content--house-account {
    margin-top: 24px;
    border: 1px solid rgba(9, 1, 28, 0.23);
    border-radius: 4px;
    padding: 12px;
    background: #ffffff;
}

.payment-method_content--house-account h4 {
    border-bottom: 1px solid #CFCFD4;
    padding-bottom: 12px;
    margin: 0 0 16px 0;
}

.payment-method_content--house-account p {
    margin-bottom: 0;
}

#checkout .payment-method_content--saved-cards select {
    width: 100%;
    max-width: none;
}

#checkout .step h3.billing-title {
    margin-top: 24px;
}

.link-authentication-element {
    margin-bottom: 24px;
}

.checkout-payment-title {
    display: flex;
    flex-wrap: nowrap;
}

.checkout-payment-title h3 {
    flex: 1 1 100%;
}


/*********************************************
Checkout Tooltip
*/

.checkout-tooltip {
    flex: 0 0 18px;
    box-shadow: none;
    position: relative;
    cursor: pointer;
}

.checkout-tooltip_content {
    display: none;
    position: absolute;
    color: #ffffff;
    font-weight: 400;
    background: rgba(9, 1, 28, 0.7);
    border-radius: 4px;
    padding: 8px;
    z-index: 999;
    font-size: 14px;
    margin-top: 6px;
}

.checkout-tooltip_content:before {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    top: -12px;
    left: auto;
    right: 12px;
    border: 6px solid transparent;
    border-bottom-color: rgba(9, 1, 28, 0.7);
}

@media (min-width: 768px) {
    .checkout-tooltip_content:before {
        left: 142px;
        right: auto;
    }
}

.checkout-tooltip--payment-title .checkout-tooltip_content {
    min-width: 270px;
    right: -10px;
}

@media (min-width: 768px) {
    .checkout-tooltip--payment-title .checkout-tooltip_content {
        right: -113px;
    }
}

/*********************************************
Checkout - Stripe Payment Request Button
*/

.btn-checkout-wallets {
    background: #000000;
    color: #ffffff;
    border: 1px solid #000000;
    width: 100%;
    height: 55px;
    padding: 11px;
    border-radius: 4px;
}

.btn-checkout-wallets_spinner {
    font-size: 10px;
    margin: 0 auto;
    text-indent: -9999em;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: btn-checkout_spinner 1.4s infinite linear;
    animation: btn-checkout_spinner 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.btn-checkout-wallets_spinner:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.btn-checkout-wallets_spinner:after {
    background: #000000;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@-webkit-keyframes btn-checkout_spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.btn-checkout-wallets_googlepay-label,
.btn-checkout-wallets_applepay-label,
.btn-checkout-wallets_amazonpay-label,
.btn-checkout-afterpayclearpay-label {
    height: auto;
    margin: 0 auto;
}

.btn-checkout-wallets_googlepay-label {
    width: 130px;
}

.btn-checkout-wallets_applepay-label {
    width: 124px;
}

.btn-checkout-afterpayclearpay-label {
    width: 150px;
}

.card-tab + .payment-method-tab--ghost {
    padding: 0;
}

.payment-method_card-tab + payment-method--active {
    margin-top: -10px;
}

.is-the-same-as-shipping-wrapper label {
    font-weight: 200;
    vertical-align: middle;
    line-height: 14px;
    margin-top: 2px;
}

.is-the-same-as-shipping-wrapper input[type="checkbox"] {
    margin: 0;
}

.is-the-same-as-shipping-wrapper-desktop {
    padding-top: 24px;
}


.form-group.billing-information-title-wrapper {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .header-mobile .mobile-cart-icon-wrapper,
    .header-mobile .navigation-mobile.open,
    .header-mobile .nav-itemWrapper.open .nav-item,
    .dropdown-menu {
        background: #565656;
    }

    .header-mobile .fa-reorder {
        color: #565656;
        font-size: 30px !important;
    }

    .nav-itemWrapper .nav-item:hover {
        background: rgba(255, 255, 255, 0.3);
        color: #777;
    }

    .navigation-mobile .nav-toggle-mobile:hover {
        background: none;
    }

    .header-mobile {
        height: 150px;
        background: #FFFFFF;
    }

    .header-mobile-grid {
        width: 100%;
        height: 150px;
    }

    .header-mobile .companyLogo-img {
        width: auto;
        max-width: 450px;
        max-height: 100px;
    }

    .header-mobile .menu-cell,
    .header-mobile .cart-cell {
        width: 65px;
    }

    .header-mobile .menu-cell,
    .header-mobile .cart-cell,
    .header-mobile .logo-cell {
        vertical-align: middle;
    }

    .header-mobile .cart-container,
    .header-mobile .menu-container {
        width: 65px;
        height: 65px;
    }

    .header-mobile .mobile-cart-icon-wrapper {
        width: 32px;
        height: 32px;
    }

    .header-mobile .mobile-cart-btn {
        font-size: 20px;
        width: 65px;
        height: 65px;
        margin: 0;
        line-height: 32px;
        display: inline-block;
        text-align: center;
        padding: 16px 0 0 16px;
    }

    .header-mobile .mobile-header-cartIcon {
        font-size: 20px;
        color: #ffffff;
        margin: 0;
    }

    .header-mobile .cart-badge-wrapper {
        position: relative;
        top: -50px;
        left: 16px;
    }

    .header-mobile .header-cartIcon {
        margin: 0;
        color: #FFFFFF;
    }

    .navigation-mobile-wrapper .background-overlay {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        display: none;
    }

    .navigation-mobile.open {
        height: 100vh;
        left: 0;
        max-width: 800px;
        overflow-y: auto;
        padding-bottom: 14em;
        position: absolute;
        top: 0;
        z-index: 2147483648;
    }

    .navigation-mobile .nav-item {
        padding: 15px;
    }

    .navigation-mobile .nav-item:hover,
    .navigation-mobile .nav-item:active,
    .navigation-mobile .nav-item:focus {
        background: rgba(255, 255, 255, 0.3) !important;
    }

    .navigation-mobile .nav-item.nav-item--subnav {
        padding: 15px 15px 15px 30px;
    }

    .navigation-mobile .nav-toggle-mobile {
        padding: 18px 0 0 0;
        width: 65px;
        height: 65px;
        color: #888888;
        z-index: auto;
    }

    .navigation-mobile .dropdown-backdrop {
        display: none;
        visibility: hidden;
    }

    .navigation-mobile .account-link {
        display: inline-block;
        font-size: 14px;
    }

    .navigation-mobile .account-link .nav-itemText {
        font-size: 0.8em;
    }

    .navigation-mobile .sign-up-log-in-separator {
        color: #428bca;
    }

    .navigation-mobile .nav-itemWrapper {
        border-bottom: solid 1px rgba(255, 255, 255, 0.12);
    }

    .navigation-mobile .nav-itemWrapper.nav-itemWrapper--navToggle {
        border: none;
    }

    .navigation-mobile .nav-item--subnav {
        text-align: left;
    }

    .navigation-mobile .nav-mobile-item-link {
        position: relative;
        z-index: 9999;
        display: block;
        padding: 0.89em 0.986em;
        text-transform: uppercase;
        cursor: pointer;
    }

    .navigation-mobile .sub-nav-mobile-item-link {
        padding: 15px 25px;
        font-weight: 200;
        position: relative;
        z-index: 9999;
        text-transform: uppercase;
    }

    .navigation-mobile .nav-itemText {
        color: #FFFFFF;
    }

    .navigation-mobile .sub-nav-mobile-item-link,
    .navigation-mobile .sub-navigation-mobile {
        background: #212121;
        color: #FFFFFF;
    }

    .navigation-mobile .nav-mobile-item-link:hover,
    .navigation-mobile .nav-mobile-item-link:active,
    .navigation-mobile .nav-mobile-item-link:focus,
    .navigation-mobile .sub-nav-mobile-item-link:hover,
    .navigation-mobile .sub-nav-mobile-item-link:active,
    .navigation-mobile .sub-nav-mobile-item-link:focus {
        background: rgba(255, 255, 255, 0.3);
        color: #FFFFFF;
        text-decoration: none;
    }

    .navigation-mobile.header-nav {
        width: auto;
        min-height: 0;
    }

    .navigation-mobile .nav-itemWrapper.open > a.nav-item:first-child,
    .navigation-mobile .nav-itemWrapper.open > a.nav-item:first-child:hover {
        background: rgba(255, 255, 255, 0.3);
    }

    .navigation-mobile.open .nav-item.nav-item--subnav:hover,
    .header-mobile .mobile-cart-icon-wrapper:hover,
    .header-mobile .fa-reorder:hover {
        opacity: .7;
    }

    .navigation-mobile .nav-itemText:hover {
        color: #FFFFFF !important;
    }
}

@media (max-width: 580px) {
    .header-mobile .companyLogo-img {
        max-width: 100%;
    }
}

.shipping_information_block .calendar_date_input_wrapper:after {
    content: "\f073";
    font-family: 'FontAwesome';
    display: inline-block;
    float: left;
    font-size: 16px;
    margin-top: -28px;
    margin-left: 245px;
    color: #BBB;
}

.shipping_information_block .calendar_date_input_wrapper input {
    background-color: initial;
}

.shipping_information_block .calendarDate .datepickr-wrapper .calendarDate-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.shipping_information_block .hide-pickup {
    display: none;
}

.form-group-calendar {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 15px;
}

.form-group-calendar:before {
    content: " ";
    display: table;
}

.form-group-calendar:after {
    content: " ";
    display: table;
    clear: both;
}

.form-group-calendar label {
    font-weight: 200 !important;
    text-align: left !important;
}

.shipping_information_block .datepickr-calendar {
    max-width: 270px;
    right: auto !important;
}

.timed_pickup select:required:invalid {
    color: #999;
    opacity: 1;
}

.timed_pickup select[disabled] {
    -webkit-appearance: none;
    background-color: #E5E5E7;
    color: #999;
    opacity: 1;
}

label[for="shipping_timed_pickup"] {
    margin-top: 4px;
    max-width: 270px;
    font-family: var(--bn--primary-font);
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px;
    letter-spacing: 0.01em !important;
    text-align: left;
}

.AfterpayMessage {
    margin-bottom: 0px !important;
    margin-top: 10px !important;
}

.AfterpayMessage-text {
    font-family: var(--bn--primary-font) !important;
    font-size: 14px !important;
    padding-left: 4px !important;
    padding-right: 8px !important;
    margin: 0px !important;
}

.AfterpayMessage-logoSvg {
    width: 82px !important;
    height: 28px !important;
    margin: 0px !important;
}

.search-results-empty {
    text-align: center;
}

.search-results-empty__btn {
    --bg-color: var(--bn--primary-color, #565656);
    --text-color: var(--bn--mobile-menu-text-color, #ffffff);
    background: var(--bg-color);
    border: 1px solid var(--bg-color);
    color: var(--text-color);
    margin-top: 2.5rem;
}

/* Override other specific selectors */
.search-results-empty .search-results-empty__btn:where(:hover, :focus) {
    background: var(--text-color);
    color: var(--bg-color);
}

.dropdown-wrapper--hover .dropdown-content::-webkit-scrollbar {
    width: 7px;
}

.dropdown-wrapper--hover .dropdown-content::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .5);
    border-radius: 5px;
}

.map-card__hours .store-hours-days {
    font-weight: 200;
    font-size: 12px;
}

.map-card__hours .store-hours-time {
    font-weight: 200;
    font-size: 12px;
}

/** Temporarily disabled per issues raised by BLOOM-12934 **/
/*.about_us-header .store-hours-days {*/
/*	font-size: 18px !important;*/
/*	font-weight: 100 !important;*/
/*}*/
/*.about_us-header .store-hours-time {*/
/*	font-size: 18px !important;*/
/*	font-weight: 100 !important;*/
/*}*/
/*.about_us-header .reset-before::before, .about_us-header .reset-before::after {*/
/*	content: none;*/
/*}*/
.footer-infoItem .store-hours-days {
    font-weight: 200;
}

.footer-infoItem .store-hours-time {
    font-weight: 200;
}

.add-message {
    --checkbox-accent: #333333;
    --border-color: #cccccc;
    --padding: 1.6rem;
    --checkbox-size: 1.6rem;
    background: #ffffff;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    container: addMessage / inline-size;
}

.add-message summary:focus-visible {
    outline: var(--bn--focus-outline);
    outline-offset: var(--bn--focus-outline-offset);
}

/* Ensures the webkit details marker stays hidden */
.add-message summary::-webkit-details-marker {
    display: none;
}

.add-message__toggle {
    align-items: flex-start;
    appearance: none;
    background: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    box-shadow: none;
    cursor: pointer;
    display: grid;
    font-size: 1.4rem;
    letter-spacing: 0.15px;
    line-height: 1.357;
    gap: 0 var(--padding);
    grid-template-columns: var(--checkbox-size) 1fr;
    list-style: none;
    padding: var(--padding);
    text-align: left;
}

[open] .add-message__toggle {
    border-bottom-color: var(--border-color);
}

.add-message__checkbox-wrapper {
    align-items: center;
    display: flex;
}

.add-message__checkbox {
    background: #ffffff;
    border: 1px solid var(--border-color);
    border-radius: 2px;
    display: flex;
    height: var(--checkbox-size);
    width: var(--checkbox-size)
}

.add-message__checkbox svg {
    display: block;
    margin: auto;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
}

[open] .add-message__checkbox {
    background: var(--checkbox-accent);
    border-color: var(--checkbox-accent);
}

[open] .add-message__checkbox svg {
    opacity: 1;
    visibility: visible;
}

.add-message__header :last-child {
    margin-bottom: 0;
}

.add-message__header .customize-step-label {
    line-height: inherit;
    margin-bottom: .5rem;
}

.add-message__instructions {
    color: #767676;
}

.add-message__drawer {
    padding: var(--padding);
}

.add-message__drawer > :last-child {
    margin-bottom: 0;
}

@supports (container: size) {
    @container (min-width: 480px) {
        .add-message__drawer {
            padding-left: calc(var(--padding) * 2 + var(--checkbox-size));
        }
    }
}

@supports not (container: size) {
    @media (min-width: 768px) {
        .add-message__drawer {
            padding-left: calc(var(--padding) * 2 + var(--checkbox-size));
        }
    }
}

.gift-message-signature {
    margin-top: 1.6rem;
}

.dropdown-wrapper--hover .dropdown-content::-webkit-scrollbar {
    width: 7px;
}

.dropdown-wrapper--hover .dropdown-content::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .5);
    border-radius: 5px;
}

.map-card__hours .store-hours-days {
    font-weight: 200;
    font-size: 12px;
}

.map-card__hours .store-hours-time {
    font-weight: 200;
    font-size: 12px;
}

.footer-infoItem .store-hours-days {
    font-weight: 200;
}

.footer-infoItem .store-hours-time {
    font-weight: 200;
}

@media (max-width: 767px) {
    .map-card__hours .store-hours-days {
        font-size: 14px;
    }

    .map-card__hours .store-hours-time {
        font-size: 14px;
    }
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
}

@media (max-width: 350px) {
    .product-list__item {
        padding-left: 0;
        width: 100%;
    }
}

.product-card {
    height: 100%;
    --line-height: 1.3666;
    background: #ffffff;
    border: none;
    color: #4B4B4B;
    container: productCard / inline-size;
    line-height: var(--line-height);
    margin: 0;
    padding: 0;
}

.product-card .product-card__link {
    color: inherit;
    display: flex;
    flex-direction: column;
}

.product-card__link:is(:hover,:focus-visible, :focus) {
    text-decoration: none;
}

.product-card__link:is(:hover,:focus-visible, :focus) .product-card__name {
    text-decoration: underline;
}

.product-card__link:focus-visible {
    outline-offset: 2px;
}

.product-card__image {
    display: block;
    height: auto;
    margin: 0;
    object-fit: cover;
    transform: scale3d(1, 1, 1);
    transition: transform .65s ease;
    width: 100%;
}

.product-card__image-wrapper {
    display: block;
    overflow: hidden;
}

.product-card__link:is(:hover,:focus-visible, :focus) .product-card__image {
    transform: scale3d(1.08, 1.08, 1.08);
}

@media (prefers-reduced-motion) {
    .product-card__image {
        transform: none !important;
        transition: none !important;
    }
}

.product-card__details {
    padding: 1.2rem .9rem;
}

@supports (container: inline-size) {

    @container productCard (min-width: 250px) {
        .product-card__details {
            padding: 1.6rem 1.2rem;
        }

    }

    @container productCard (min-width: 350px) {
        .product-card__details {
            padding: 1.8rem 2.6rem;
        }

    }
}

@supports not (container: inline-size) {
    @media (min-width: 768px) {
        .product-card__details {
            padding: 1.6rem 1.2rem;
        }
    }
}

.product-card__details :first-child {
    margin-top: 0;
}

.product-card__details :last-child {
    margin-bottom: 0;
}

.product-card__name {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    --font-size: 1.4rem;
    display: -webkit-box;
    color: var(--text-color);
    font-family: var(--bn--primary-font);
    font-size: var(--font-size);
    line-height: inherit;
    margin-bottom: 1.2rem;
    margin-top: 0;
    max-height: calc(var(--font-size) * var(--line-height) * 2);
    overflow: hidden;
    height: 38.25px;
}

/* Override specific selector in typography.css for h2's */
.product-card__name {
    font-weight: var(--bn--font-medium) !important;
}

.product-card__price {
    font-family: var(--bn--primary-font);
    font-size: 1.6rem;
    font-weight: var(--bn--font-bold);
    margin-bottom: 1rem;
}

@supports (container: inline-size) {

    @container productCard (min-width: 250px) {
        .product-card__name {
            --font-size: 1.6rem;
        }

        .product-card__price {
            font-size: 1.8rem;
        }

    }
}

@supports not (container: inline-size) {
    @media (min-width: 768px) {
        .product-card__name {
            --font-size: 1.6rem;
        }

        .product-card__price {
            font-size: 1.8rem;
        }
    }
}


.product-card__tag {
    color: #282A2C;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 5px;
}

.product-card__tag i {
    display: inline-block;
    background-size: 100%;
    width: 20px;
    height: 18px;
    position: relative;
    top: 5px;
}

.product-card__tag i.same-day-delivery, .product-card__tag i.next-day-delivery {
    background: url(../images/truck-regular.svg) 0 0 no-repeat;
}

.product-card__tag i.free-pickup {
    background: url(../images/shopping-bag-regular.svg) 0 0 no-repeat;
    top: 3px;
}

.product-card__tag i.best-seller {
    background: url(../images/sparkles-regular.svg) 0 0 no-repeat;
}

@supports (container: inline-size) {

    @container productCard (min-width: 250px) {
        .product-card__tag {
            font-size: 1.4rem;
        }

    }
}

@supports not (container: inline-size) {
    @media (min-width: 768px) {
        .product-card__details {
            font-size: 1.4rem;
        }
    }
}

.needed-by-time-label,
.needed-by-time-price {
    color: var(--bn--primary-font-color);
}

.needed-by-time-label {
    font-size: 14px;
}

.needed-by-time-price {
    font-size: 12px;
}

.timed-delivery-flag {
    display: flex;
    gap: 1.5rem;
    list-style: none;
    margin: 12px 0 0;
    padding: 0;
}

input[type="radio"] + .radio-label--button {
    align-items: center;
    border: 1px solid var(--bn--secondary-font-color);
    color: var(--bn--primary-font-color);
    cursor: pointer;
    display: flex;
    font-size: 16px;
    justify-content: center;
    min-height: 46px;
    min-width: 110px;
    padding: 12px 16px;
    outline: 2px solid transparent;
    transition: outline-color var(--bn--transition-duration) var(--bn--transition-easing);
    width: initial !important;
}

input[type="radio"] + .radio-label--button:hover,
input[type="radio"]:checked + .radio-label--button {
    outline: 2px solid var(--bn--primary-font-color);
}

.grecaptcha-badge {
    display: none;
}

/**** Super Footer (elements in all pages) ******/

#super-footer .copyright__social-link:hover {
    text-decoration: none;
}

#super-footer .copyright__links {
    font-size: 12px;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
    clear: both;
}

#super-footer .copyright__link {
    display: inline;
    margin: 0 20px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 300 !important;
    color: #346FA1;
}

#super-footer .copyright__link--single {
    display: block;
}

@media screen and (min-width: 768px) {
    #super-footer .copyright__link--single {
        display: inline;
    }
}

#super-footer .copyright__all-rights-reserved {
    padding-top: 15px;
    font-size: 12px;
    color: #3d3d3d;
}

/**** End Super Footer (elements in all pages) ******/

/**** Top occasions on PLP ******/

.top-occasions {
    margin-top: 20px;
}
.top-occasions .row {
    background-color: #FFF;
}
.top-occasions .row:first-child {
    padding: 20px 25px;
    margin-top: 20px;
}

.top-occasions .header {
    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 20px 0;
}

.top-occasions .footer__block {
    margin: 20px 0;
    background: #fff;
    width: 100%;
}
.top-occasions .footer__block--about-owner {
    margin-top: 60px !important;
}
.top-occasions .footer__block--best-of-badge {
    margin-bottom: -48px !important;
}
@media only screen and (min-width: 768px) {
    .top-occasions .footer__block {
        margin: 20px auto;
    }
}
.top-occasions .footer__underline {
    margin: 15px auto;
    width: 75px;
    height: 1px;
    border-bottom: 1px solid #bbb;
}

/**** End of Top occasions Reviews on PLP ******/

/**** Reviews on PLP ******/

.reviews-slider {
    position: relative;
    padding: 50px 25px;
}

.reviews-slider_header span {
    display: none;
    font-size: 32px;
    font-weight: 100 !important;
}

@media only screen and (min-width: 768px) {
    .reviews-slider_header span {
        display: inline;
    }
}

.reviews-slider_toggle {
    background: transparent;
    border: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 65px;

    &:disabled {
        cursor: not-allowed;
    }
}

.reviews-slider_toggle--left {
    left: 15px;
}

.reviews-slider_toggle--right {
    right: 15px;
}

.reviews-slider_no-reviews {
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .reviews-slider .review_left, .reviews-slider .review_right {
        float: left;
        margin: 10px 0;
        padding: 5px 10px;
    }
}

.reviews-slider .review_left {
    margin-bottom: 10px;
    width: 30%;
    text-align: right;
}

.reviews-slider .review_right {
    width: 70%;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.reviews-slider .review_author span {
    font-weight: 600 !important;
    font-size: 14px;
    text-transform: capitalize;
    margin: 0 0 10px 0;
}

@media only screen and (min-width: 768px) {
    .reviews-slider .review_author {
        font-size: 15px;
    }
}

.reviews-slider .review_text-wrapper {
    overflow: hidden;
    line-height: 2rem;
    max-height: 8.2rem;
    margin-bottom: 10px;
}

.reviews-slider .review_text-wrapper--expanded {
    max-height: none !important;
    height: auto !important;
}

@media only screen and (min-width: 768px) {
    .reviews-slider .review_text-wrapper {
        height: 6.2rem;
    }
}

.reviews-slider .review_text {
    width: 55%;
    margin: 5px 20px 0 0;
    font-size: 13px;
}

@media only screen and (min-width: 768px) {
    .reviews-slider .review_text {
        width: 80%;
    }
}

.reviews-slider .review_show-more {
    cursor: pointer;
    display: none;
    margin: 10px 0 0 0;
    padding: 5px 0;
    font-size: 12px;
}

.reviews-slider .review_show-more--visible {
    background: none;
    border: none;
    display: inline;
}

.reviews-slider .review_show-more:hover {
    text-decoration: underline;
}

.reviews-slider .review_date {
    display: none;
    font-weight: 600;
    font-size: 12px;
    color: #5d5d5d;
}

@media only screen and (min-width: 768px) {
    .reviews-slider .review_date {
        display: block;
    }
}

.reviews-slider .review--single {
    margin: 25px 0;
    text-align: center;
}

.reviews-slider .review--single .review_left {
    width: 100%;
    text-align: center;
}

.reviews-slider .review--single .review_right {
    width: 100%;
    margin: 10px 0;
    text-align: center;
}

.reviews-slider .review--single .review_text {
    width: 60%;
    margin: 20px auto;
    text-align: left;
}

@media only screen and (min-width: 768px) {
    .reviews-slider .review--single .review_text {
        font-size: 14px;
    }
}

.reviews-slider .review--hidden {
    display: none;
}

.reviews-slider .review_show-more-review-container {
    text-align: center;
}

.reviews-slider .review_show-more-review-container button {
    background-color: #D8247F;
    color: white;
    border-radius: 5px;
    border: 0;
    width: 150px;
    height: 44px;
    font-weight: 200;
    font-size: 14px;
}

.rating-box {
    display: inline-block;
    background: url('../../../../../js/jquery/raty/images/icon-star-outline.svg') repeat-x;
    background-size: 16px;
    position: relative;
    width: 79px;
    height: 16px;
    margin-right: 3px;
}
.rating-box .rating {
    height: 16px;
    background: url('../../../../../js/jquery/raty/images/icon-star-filled.svg') repeat-x;
    background-size: 16px;
}

/**** End of Reviews on PLP ******/

.holiday-pricing {
    display: flex;
}

.holiday-pricing .fa {
    --bn--holiday-pricing-icon-color: var(--bn--primary-font-color);

    color: var(--bn--holiday-pricing-icon-color);
    font-size: 20px;
}

.holiday-pricing__cart {
    --bn--holiday-pricing-cart-bg-color: #f5f5f5;
    background: var(--bn--holiday-pricing-cart-bg-color);
    border-radius: 5px;
    padding: 12px;
    gap: 8px;
}

.holiday-pricing__cart span {
    font-weight: var(--bn--font-demibold) !important;
}

/**
 * Disable auto-zoom on inputs
 * @see https://stackoverflow.com/a/73558386
 */
@media only screen and (hover: none) and (pointer: coarse) {
    input:focus,
    select:focus,
    textarea:focus {
        font-size: 16px !important;
    }
}

.homeAlert-container .vendorAlert {
    margin-bottom: 24px;
}

/** Subscriptions */

.subscription-customize-form {
    text-align: center;
    padding: 12px;
    max-width: 900px;
    margin: 20px auto 0;
}

.single_tier_subscription-customize-form {
    max-width: 800px;
    margin: 20px auto 0;
}

.subscription-customize-form .btn-product-upgrade-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.subscription-customize-form .btn-product-upgrade {
    max-width: 100%;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
}

.subscription-customize-form .btn-product-upgrade-list {
    display: flex;
    justify-content: center;
    column-gap: 16px;
    row-gap: 16px;
}

.subscription-customize-form .btn-product-upgrade:not(.active) i.fa-check {
    display: none;
}

.subscription-customize-form .btn-product-upgrade:not(.active) .selected-text {
    display: none;
}

.subscription-customize-form .btn-product-upgrade.active .select-text {
    display: none;
}

.subscription-customize-form .btn-product-upgrade.active {
    border: 2px solid #09011CDE;
}

.subscription-customize-form .btn-product-upgrade__name, .subscription-customize-form .btn-product-upgrade__price span {
    font-weight: 800 !important;
    font-size: 20px;
    text-transform: capitalize;
}

.subscription-customize-form__disclaimer {
    font-size: 12px;
}

.subscription-customize-form .btn-product-upgrade__select {
    width: 100%;
    padding: 12px 0;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subscription-customize-form .btn-product-upgrade__select i.fa-check {
    font-size: 20px;
}

.subscription-customize-form .btn-product-upgrade__most-popular {
    height: 38px;
    margin: 0;
}

.subscription-customize-form .btn-product-upgrade__most-popular--active {
    border-radius: 4px;
    background-color: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #696475;
}

.subscription-customize-form .btn-product-upgrade__select span {
    font-size: 22px;
}

.subscription-customize-form__header {
    font-weight: 700 !important;
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 0;
}

.subscription-customize-form__header #product-cost {
    font-weight: inherit !important;
    font-size: inherit !important;
}

.subscription-customize-form__header #product-interval {
    font-weight: inherit !important;
    font-size: inherit !important;
}

.subscription-customize-form__description {
    padding-top: 16px;
    font-size: 18px;
}

.subscription-customize-form #subscription-widget {
    padding-top: 8px;
}

.subscription-customize-form__terms {
    font-weight: 100;
}

.subscription-customize-form__checkout-cta {
    border: none;
    font-size: 22px;
    padding: 24px 0;
    width: 523px;
    max-width: 100%;
}

button.btn-product-upgrade.single_tier_btn-product-upgrade {
    border-radius: 8px;
}

.btn-product-upgrade-container.single_tier_btn-product-upgrade-container {
    flex: initial;
}

.subscription-customize-form #frequency-date-widget label {
    display: block;
    font-weight: 200;
}

.section-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

h3.subscription-customize-form__header.form_header_frequency {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left;
}

h3.subscription-customize-form__header.form_header_terms,
h3.subscription-customize-form__header.form_header_tier {
    text-align: left;
}

h3.subscription-customize-form__header.form_header_terms {
    margin-bottom: 20px;
}

.frequency-options {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
}

.frequency-option {
    display: flex;
    gap: 16px;
    padding: 18px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    cursor: pointer;
    background: #fff;
    transition: all 0.2s ease;
}

button.btn-product-upgrade:hover,
.frequency-option:hover {
    cursor: pointer;
}

.frequency-option.selected {
    border: 2px solid #09011CDE;
}

.radio-button {
    width: 24px;
    height: 24px;
    border: 2px solid #E0E0E0;
    border-radius: 50%;
    flex-shrink: 0;
    margin-top: 2px;
    transition: all 0.2s ease;
}

.frequency-option.selected .radio-button {
    border-color: #09011CDE;
    background-color: #09011CDE;
    box-shadow: inset 0 0 0 4px #fff;
}

.frequency-content {
    flex: 1;
    text-align: left;
}

.frequency-content h4 {
    font-size: 20px;
    margin: 2px 0 6px 0;
    color: #000;
}

.description {
    font-size: 16px;
    margin-bottom: 0;
}

.schedule {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #0346cc;
    margin-bottom: 0;
    font-size: 14px;
    margin: 8px auto;
}

.point {
    font-size: 16px;
    color: #535863;
}

.scheduling-notes {
    background: #f8f9ff;
    border-radius: 8px;
    padding: 18px;
    text-align: center;
}

.scheduling-notes h3 {
    margin-top: 0;
    color: #0346cc;
    font-size: 16px;
    text-align: left;
}

.scheduling-notes p {
    text-align: left;
    margin-bottom: 2px;
}

p.faq {
    font-style: italic;
}

.subscription-customize-form .btn-product-upgrade-container i.most-popular {
    background: url(../images/icon-sparkles.svg) 0 0 no-repeat;
    width: 16px;
    height: 16px;
}

.subscription-customize-form-section {
    margin-bottom: 30px;
}

.subscription-customize-form-terms-section {
    position: relative;
}

.subscription-customize-form__terms-text {
    text-align: left;
}

.subscription-customize-form-terms-section .subscription_error {
    position: absolute;
    left: 0;
    right: 0;
    color: #ef4444;
    bottom: -20px;
    text-align: left;
}

.accept-terms {
    text-align: left;
}

.subscription-list-page .subscription-list-page__title {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 700 !important;
}

.subscription-list-page .subscription-list-page__description {
    font-size: 16px;
}

.subscription-list-page .product-card {
    display: flex;
    flex-direction: column;
    padding: 12px;
}

.subscription-list-page .product-card__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.subscription-list-page .product-card__image-wrapper {
    height: 264px;
}

.subscription-list-page .product-card__image {
    border-radius: 8px;
}

.subscription-list-page .product-list {
    margin-top: 40px;
}

.subscription-list-page .product-list__additional-info {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}

.subscription-list-page .product-card__details {
    padding: 16px 0;
}

.subscription-list-page .product-card__link .product-card__name, .subscription-list-page .product-card__link .product-card__name:hover {
    text-decoration: none;
}

.subscription-list-page .product-card__name, .subscription-list-page .product-card__price > * {
    font-weight: 800 !important;
}

.subscription-list-page .product-card__name {
    height: auto;
    max-height: none;
    overflow: auto;
    margin-bottom: 8px;
    font-size: 24px;
    -webkit-line-clamp: unset;
    word-wrap: break-word;
}

.subscription-list-page .product-card__price {
    margin-bottom: 8px;
}

.subscription-list-page .product-card__price  * {
    font-size: 20px;
}

.subscription-list-page .product-card__disclaimer {
    font-size: 12px;
}

.subscription-list-page .btn-success {
    padding: 12px 0;
    text-align: center;
    font-size: 22px;
    border-radius: 4px;
    font-weight: 300;
}

.multi-tier-max-width {
    max-width: 820px;
    margin: 0 auto;
}

.subscription-customize-form-terms-section.multi-tier-max-width {
    max-width: 820px;
    margin: 0 auto 32px;
}

@media screen and (max-width: 976px) {
    .subscription-customize-form .btn-product-upgrade-list {
        flex-direction: column;
    }

    .subscription-customize-form .btn-product-upgrade {
        max-width: none;
        align-items: center;
    }

    .btn-product-upgrade__most-popular--spacer {
        display: none;
    }
}
